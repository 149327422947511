.App {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.App-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.App-header h1 {
  text-align: center;
  margin-bottom: 2rem;
}
.App-content {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}
.App-preview {
  margin-right: 20px;
  overflow: auto;
  flex-shrink: 0;
}
.App-editor {
  width: 100%;
}

@media (max-width: 1200px) {
  .App-content {
    display: block;
  }
  .App-preview {
    margin-bottom: 20px;
  }
}

.App-size label {
  margin-right: 10px;
}
.App-size button {
  padding: 4px 20px;
  background: rgba(0, 0, 0, 0.1);
  margin: 1px;
  border: 2px solid transparent;
}
.App-size button.selected {
  border: 2px solid black;
}
