:root {
  --black: #000;
  --black-a90: rgba(0,0,0,0.9);
  --black-a75: rgba(0,0,0,0.75);
  --black-a60: rgba(0,0,0,0.6);
  --black-a45: rgba(0,0,0,0.45);
  --black-a30: rgba(0,0,0,0.3);
  --black-a15: rgba(0,0,0,0.15);
  --black-a12: rgba(0,0,0,0.12);
  --black-a08: rgba(0,0,0,0.08);
  --black-a04: rgba(0,0,0,0.04);
  --white: #fff;
  --white-a90: hsla(0,0%,100%,0.9);
  --white-a85: hsla(0,0%,100%,0.85);
  --white-a75: hsla(0,0%,100%,0.75);
  --white-a70: hsla(0,0%,100%,0.7);
  --white-a60: hsla(0,0%,100%,0.6);
  --white-a55: hsla(0,0%,100%,0.55);
  --white-a40: hsla(0,0%,100%,0.4);
  --white-a25: hsla(0,0%,100%,0.25);
  --white-a18: hsla(0,0%,100%,0.18);
  --white-a12: hsla(0,0%,100%,0.12);
  --white-a06: hsla(0,0%,100%,0.06);
  --blue-10: #f6fbff;
  --blue-20: #e8f3ff;
  --blue-30: #d0e8ff;
  --blue-40: #a8d4ff;
  --blue-50: #70b5f9;
  --blue-50-a20: rgba(112,181,249,0.2);
  --blue-50-a30: rgba(112,181,249,0.3);
  --blue-60: #378fe9;
  --blue-70: #0a66c2;
  --blue-80: #004182;
  --blue-90: #09223b;
  --cool-gray-10: #f9fafb;
  --cool-gray-20: #eef3f8;
  --cool-gray-30: #dce6f1;
  --cool-gray-40: #c0d1e2;
  --cool-gray-50: #9db3c8;
  --cool-gray-60: #788fa5;
  --cool-gray-70: #56687a;
  --cool-gray-80: #38434f;
  --cool-gray-85: #293138;
  --cool-gray-90: #1d2226;
  --warm-gray-10: #fafaf9;
  --warm-gray-20: #f3f2ef;
  --warm-gray-30: #e9e5df;
  --warm-gray-40: #d6cec2;
  --warm-gray-40-a50: rgba(214,206,194,0.5);
  --warm-gray-50: #b9af9f;
  --warm-gray-60: #958b7b;
  --warm-gray-70: #6e6558;
  --warm-gray-80: #474139;
  --warm-gray-90: #211f1c;
  --warm-red-10: #fff9f7;
  --warm-red-20: #ffefea;
  --warm-red-30: #ffded5;
  --warm-red-40: #fdc2b1;
  --warm-red-50: #f5987e;
  --warm-red-60: #e16745;
  --warm-red-70: #b24020;
  --warm-red-80: #762812;
  --warm-red-90: #351a12;
  --teal-10: #eefdff;
  --teal-20: #d5f9fe;
  --teal-30: #aef0fa;
  --teal-40: #79deee;
  --teal-50: #44bfd3;
  --teal-50-a30: rgba(68,191,211,0.3);
  --teal-60: #2199ac;
  --teal-70: #13707e;
  --teal-80: #104952;
  --teal-90: #0e2428;
  --purple-10: #fcf9ff;
  --purple-20: #f7efff;
  --purple-30: #eedfff;
  --purple-40: #dec5fd;
  --purple-40-a15: rgba(222,197,253,0.15);
  --purple-50: #c79ef7;
  --purple-60: #a872e8;
  --purple-70: #8344cc;
  --purple-70-a15: rgba(131,68,204,0.15);
  --purple-80: #592099;
  --purple-90: #2c1349;
  --system-red-10: #fff9f9;
  --system-red-20: #ffeeef;
  --system-red-30: #ffddde;
  --system-red-40: #ffbfc1;
  --system-red-50: #fc9295;
  --system-red-60: #f55257;
  --system-red-70: #cc1016;
  --system-red-80: #8a0005;
  --system-red-90: #46080a;
  --system-green-10: #f0fdf7;
  --system-green-20: #dbf9eb;
  --system-green-30: #b6f2d6;
  --system-green-40: #7ce3b3;
  --system-green-50: #3ec786;
  --system-green-60: #13a05f;
  --system-green-70: #057642;
  --system-green-80: #004d2a;
  --system-green-90: #022716;
  --pink-10: #fff8ff;
  --pink-20: #ffedfe;
  --pink-30: #ffdafd;
  --pink-40: #fcb9f9;
  --pink-50: #f489ee;
  --pink-60: #dd51d6;
  --pink-70: #b11faa;
  --pink-80: #770c72;
  --pink-90: #3d0a3b;
  --amber-10: #fdfaf5;
  --amber-20: #fbf1e2;
  --amber-30: #fce2ba;
  --amber-40: #f8c77e;
  --amber-50: #e7a33e;
  --amber-60: #c37d16;
  --amber-70: #915907;
  --amber-80: #5d3b09;
  --amber-90: #2a1f0e;
  --copper-10: #fcfaf9;
  --copper-20: #fcf0ed;
  --copper-30: #fadfd8;
  --copper-40: #f2c5b8;
  --copper-50: #e0a191;
  --copper-60: #be7b6a;
  --copper-70: #8f5849;
  --copper-80: #5d392f;
  --copper-90: #2d1d19;
  --green-10: #f6fcf4;
  --green-20: #e3f9d8;
  --green-30: #c7f1b2;
  --green-40: #a5df89;
  --green-50: #7fc15e;
  --green-60: #5f9b41;
  --green-70: #44712e;
  --green-80: #2f4922;
  --green-90: #1b2416;
  --sage-10: #f8fbf6;
  --sage-20: #eaf6e4;
  --sage-30: #d7ebce;
  --sage-40: #bdd7b0;
  --sage-50: #9db88f;
  --sage-60: #7b9370;
  --sage-70: #5a6b51;
  --sage-80: #3a4535;
  --sage-90: #1e221c;
  --lime-10: #f9fce9;
  --lime-20: #eff8b9;
  --lime-30: #dfee89;
  --lime-40: #c6d957;
  --lime-50: #a6ba32;
  --lime-60: #83941f;
  --lime-70: #5f6c16;
  --lime-80: #3e4613;
  --lime-90: #20230f;
  --camo-10: #fafbf3;
  --camo-20: #f1f4e4;
  --camo-30: #e4e8cc;
  --camo-40: #cdd3ac;
  --camo-50: #aeb48a;
  --camo-60: #8a8f6c;
  --camo-70: #65684e;
  --camo-80: #414335;
  --camo-90: #21211d;
  --smoke-10: #f8fafb;
  --smoke-20: #edf3f4;
  --smoke-30: #dbe7e9;
  --smoke-40: #bfd3d6;
  --smoke-50: #a0b4b7;
  --smoke-60: #7d8f92;
  --smoke-70: #5b696b;
  --smoke-80: #3c4345;
  --smoke-90: #1f2122;
  --lavender-10: #fbf9fd;
  --lavender-20: #f4f1f9;
  --lavender-30: #eae2f3;
  --lavender-40: #d7cae7;
  --lavender-50: #bba9d1;
  --lavender-60: #9983b1;
  --lavender-70: #715e86;
  --lavender-80: #493d57;
  --lavender-90: #241f29;
  --mauve-10: #fcf9fc;
  --mauve-20: #f9eff8;
  --mauve-30: #f2e0f1;
  --mauve-40: #e5c6e3;
  --mauve-50: #cea2cc;
  --mauve-60: #ac7da9;
  --mauve-70: #80597e;
  --mauve-80: #523a51;
  --mauve-90: #271e27;
  --system-gray-10: #fafafa;
  --system-gray-20: #f2f2f2;
  --system-gray-30: #e5e5e5;
  --system-gray-40: #cfcfcf;
  --system-gray-50: #b0b0b0;
  --system-gray-60: #8c8c8c;
  --system-gray-70: #666;
  --system-gray-80: #424242;
  --system-gray-90: #212121;
  --system-orange-10: #fff9f6;
  --system-orange-20: #ffefe8;
  --system-orange-30: #ffdfd1;
  --system-orange-40: #ffc1a7;
  --system-orange-50: #ff9466;
  --system-orange-60: #eb6126;
  --system-orange-70: #b93a04;
  --system-orange-80: #792603;
  --system-orange-90: #351a0f;
  --transparent: transparent;
  --transparent-white: hsla(0,0%,100%,0);
  --transparent-black: transparent;
  --elevation-flat: none;
  --elevation-lined: 0px 0px 0px 1px var(--color-border-faint);
  --elevation-raised: var(--elevation-lined),0px 4px 4px var(--color-shadow);
  --voyager-elevation-text-theater: 0 0 2px var(--black-a60);
  --illustration-microspot-size-small: 4.8rem;
  --illustration-microspot-size-large: 6.4rem;
  --illustration-spot-size-small: 12.8rem;
  --illustration-spot-size-large: 25.6rem;
  --checkbox-checked-background-position: 0 -2.4rem;
  --checkbox-checked-disabled-background-position: 0 -36.6rem;
  --select-chevron-background-position: top -31.1rem right .7rem;
  --select-chevron-background-position-rtl: top -31.1rem left .7rem;
  --acttachment-icon-filter: brightness(1);
  --image-filter: invert(0%);
  --delay-short: 52ms;
  --delay-medium: 84ms;
  --duration-xxfast: 52ms;
  --duration-xfast: 84ms;
  --duration-fast: 132ms;
  --duration-moderate: 216ms;
  --duration-slow: 336ms;
  --duration-xslow: 560ms;
  --duration-xxslow: 916ms;
  --ease-standard: cubic-bezier(.34,0,.21,1);
  --ease-emphasized: cubic-bezier(.13,0,.08,1);
  --ease-decelerate: cubic-bezier(0,0,0.21,1);
  --ease-accelerate: cubic-bezier(0.55,0,1,1);
  --motion-transition-slide-in: var(--duration-fast) var(--ease-decelerate);
  --motion-transition-slide-out: var(--duration-fast) var(--ease-accelerate);
  --motion-transition-appear: var(--duration-xxfast) var(--ease-standard);
  --motion-transition-hide: var(--duration-xxfast) var(--ease-standard);
  --motion-transition-hover-in: var(--duration-xfast) var(--ease-standard);
  --motion-transition-hover-out: var(--duration-xfast) var(--ease-standard);
  --motion-transition-hover-in-raised: var(--duration-fast) var(--ease-standard);
  --motion-transition-hover-out-raised: var(--duration-fast) var(--ease-standard);
  --motion-transition-expanding: var(--duration-xxfast) var(--ease-standard);
  --motion-transition-hover-shrinking: var(--duration-xxfast) var(--ease-standard);
  --motion-transition-zoom-in: var(--duration-fast) var(--ease-decelerate);
  --motion-transition-hover-zoom-out: var(--duration-fast) var(--ease-accelerate);
  --corner-radius-small: 0.4rem;
  --corner-radius-medium: 0.8rem;
  --corner-radius-large: 1.6rem;
  --corner-radius-xlarge: 2.4rem;
  --corner-radius-full: 50%;
  --free: var(--corner-radius-medium);
  --attached-top: 0 0 var(--corner-radius-medium) var(--corner-radius-medium);
  --attached-right: var(--corner-radius-medium) 0 0 var(--corner-radius-medium);
  --attached-bottom: var(--corner-radius-medium) var(--corner-radius-medium) 0 0;
  --attached-left: 0 var(--corner-radius-medium) var(--corner-radius-medium) 0;
  --point-top-left: 0 var(--corner-radius-medium) var(--corner-radius-medium) var(--corner-radius-medium);
  --point-top-right: var(--corner-radius-medium) 0 var(--corner-radius-medium) var(--corner-radius-medium);
  --point-bottom-right: var(--corner-radius-medium) var(--corner-radius-medium) 0 var(--corner-radius-medium);
  --point-bottom-left: var(--corner-radius-medium) var(--corner-radius-medium) var(--corner-radius-medium) 0;
  --spacing-half-x: 0.4rem;
  --spacing-one-x: 0.8rem;
  --spacing-one-and-a-half-x: 1.2rem;
  --spacing-two-x: 1.6rem;
  --spacing-three-x: 2.4rem;
  --spacing-four-x: 3.2rem;
  --spacing-six-x: 4.8rem;
  --spacing-eight-x: 6.4rem;
  --spacing-twelve-x: 9.6rem;
  --spacing-sixteen-x: 12.8rem;
  --spacing-twenty-four-x: 19.2rem;
  --spacing-thirty-two-x: 25.6rem;
  --font-size-xsmall: 1.2rem;
  --font-size-small: 1.4rem;
  --font-size-medium: 1.6rem;
  --font-size-large: 2rem;
  --font-size-xlarge: 2.4rem;
  --font-size-display-small: 2.4rem;
  --font-size-display-medium: 3.2rem;
  --font-size-display-large: 4.8rem;
  --font-size-display-xlarge: 6.4rem;
  --font-weight-regular: 400;
  --font-weight-bold: 600;
  --line-height-default: 1.25;
  --line-height-open: 1.5;
  --paragraph-spacing: 1.2rem;
  --color-transparent: var(--transparent-white);
  --color-transparent-on-on-dark: var(--transparent-black);
  --color-brand: var(--blue-70);
  --color-brand-active: var(--blue-80);
  --color-brand-alt: var(--black);
  --color-brand-alt-active: var(--black);
  --color-brand-on-dark: var(--white-a90);
  --color-brand-on-dark-active: var(--white-a70);
  --color-brand-accent-1: var(--amber-70);
  --color-brand-accent-1-active: var(--amber-80);
  --color-brand-accent-1-on-dark: var(--amber-40);
  --color-brand-accent-1-on-dark-active: var(--amber-50);
  --color-background-brand-accent-1: var(--amber-30);
  --color-background-brand-accent-1-dark: var(--amber-80);
  --color-brand-accent-2: var(--copper-70);
  --color-brand-accent-2-active: var(--copper-80);
  --color-brand-accent-2-on-dark: var(--copper-40);
  --color-brand-accent-2-on-dark-active: var(--copper-50);
  --color-background-brand-accent-2: var(--copper-30);
  --color-background-brand-accent-2-dark: var(--copper-80);
  --color-brand-accent-3: var(--sage-70);
  --color-brand-accent-3-active: var(--sage-80);
  --color-brand-accent-3-on-dark: var(--sage-40);
  --color-brand-accent-3-on-dark-active: var(--sage-50);
  --color-background-brand-accent-3: var(--sage-30);
  --color-background-brand-accent-3-dark: var(--sage-80);
  --color-brand-accent-4: var(--cool-gray-70);
  --color-brand-accent-4-active: var(--cool-gray-80);
  --color-brand-accent-4-on-dark: var(--cool-gray-40);
  --color-brand-accent-4-on-dark-active: var(--cool-gray-50);
  --color-background-brand-accent-4: var(--cool-gray-30);
  --color-background-brand-accent-4-dark: var(--cool-gray-80);
  --color-brand-accent-5: var(--warm-gray-70);
  --color-brand-accent-5-active: var(--warm-gray-80);
  --color-brand-accent-5-on-dark: var(--warm-gray-40);
  --color-brand-accent-5-on-dark-active: var(--warm-gray-50);
  --color-background-brand-accent-5: var(--warm-gray-30);
  --color-background-brand-accent-5-dark: var(--warm-gray-80);
  --color-action: var(--blue-70);
  --color-action-active: var(--blue-80);
  --color-action-on-dark: var(--blue-50);
  --color-action-on-dark-active: var(--blue-40);
  --color-signal-positive: var(--system-green-70);
  --color-signal-positive-active: var(--system-green-80);
  --color-signal-positive-on-dark: var(--system-green-50);
  --color-signal-positive-on-dark-active: var(--system-green-40);
  --color-signal-caution: var(--system-orange-70);
  --color-signal-caution-active: var(--system-orange-80);
  --color-signal-caution-on-dark: var(--system-orange-50);
  --color-signal-caution-on-dark-active: var(--system-orange-40);
  --color-signal-negative: var(--system-red-70);
  --color-signal-negative-active: var(--system-red-80);
  --color-signal-negative-on-dark: var(--system-red-50);
  --color-signal-negative-on-dark-active: var(--system-red-40);
  --color-signal-neutral: var(--cool-gray-70);
  --color-signal-neutral-active: var(--cool-gray-80);
  --color-signal-neutral-on-dark: var(--cool-gray-50);
  --color-signal-neutral-on-dark-active: var(--cool-gray-40);
  --color-text: var(--black-a90);
  --color-text-active: var(--black-a90);
  --color-text-on-dark: var(--white-a90);
  --color-text-on-dark-active: var(--white-a90);
  --color-text-stick: var(--black-a90);
  --color-text-stick-active: var(--black-a90);
  --color-text-on-dark-flip: var(--white);
  --color-text-on-dark-flip-active: var(--white-a70);
  --color-text-shift-on-dark-flip: var(--white);
  --color-text-shift-on-dark-flip-active: var(--white-a90);
  --color-text-low-emphasis: var(--black-a60);
  --color-text-low-emphasis-active: var(--black-a90);
  --color-text-low-emphasis-on-dark: var(--white-a60);
  --color-text-low-emphasis-on-dark-active: var(--white-a90);
  --color-text-low-emphasis-shift: var(--black-a60);
  --color-text-low-emphasis-shift-active: var(--black-a90);
  --color-text-low-emphasis-stick: var(--black-a60);
  --color-text-low-emphasis-stick-active: var(--black-a90);
  --color-text-disabled: var(--black-a30);
  --color-text-on-dark-disabled: var(--white-a40);
  --color-text-link-visited: var(--purple-70);
  --color-text-link-visited-active: var(--purple-80);
  --color-text-link-visited-on-dark: var(--purple-40);
  --color-text-link-visited-on-dark-active: var(--purple-50);
  --color-text-solid: var(--system-gray-90);
  --color-text-solid-active: var(--system-gray-90);
  --color-text-solid-on-dark: var(--system-gray-20);
  --color-text-solid-on-dark-active: var(--system-gray-40);
  --color-text-solid-low-emphasis: var(--system-gray-70);
  --color-text-solid-low-emphasis-active: var(--system-gray-90);
  --color-text-solid-low-emphasis-on-dark: var(--system-gray-40);
  --color-text-solid-low-emphasis-on-dark-active: var(--system-gray-50);
  --color-text-solid-disabled: var(--system-gray-50);
  --color-text-solid-on-dark-disabled: var(--system-gray-60);
  --color-icon: var(--black-a60);
  --color-icon-active: var(--black-a90);
  --color-icon-on-dark: var(--white-a90);
  --color-icon-on-dark-active: var(--white-a90);
  --color-icon-stick: var(--black-a60);
  --color-icon-stick-active: var(--black-a90);
  --color-icon-on-dark-flip: var(--white-a90);
  --color-icon-on-dark-flip-active: var(--white-a70);
  --color-icon-nav: var(--black-a60);
  --color-icon-nav-active: var(--black-a90);
  --color-icon-nav-on-dark: var(--white-a70);
  --color-icon-nav-on-dark-active: var(--white);
  --color-icon-nav-selected: var(--black-a90);
  --color-icon-nav-selected-active: var(--black-a90);
  --color-icon-nav-selected-on-dark: var(--white-a90);
  --color-icon-nav-selected-on-dark-active: var(--white-a90);
  --color-icon-disabled: var(--black-a30);
  --color-icon-on-dark-disabled: var(--white-a40);
  --color-border: var(--black-a60);
  --color-border-active: var(--black-a90);
  --color-border-on-dark: var(--white-a90);
  --color-border-on-dark-active: var(--white-a90);
  --color-border-low-emphasis: var(--black-a30);
  --color-border-low-emphasis-active: var(--black-a60);
  --color-border-low-emphasis-on-dark: var(--white-a40);
  --color-border-low-emphasis-on-dark-active: var(--white-a25);
  --color-border-faint: var(--black-a08);
  --color-border-faint-on-dark: var(--white-a12);
  --color-border-gap: var(--white);
  --color-border-gap-on-dark: var(--black-a60);
  --color-background-container: var(--white);
  --color-background-container-tint: var(--cool-gray-10);
  --color-background-container-dark: var(--cool-gray-90);
  --color-background-container-dark-tint: var(--cool-gray-85);
  --color-background-canvas: var(--warm-gray-20);
  --color-background-canvas-mobile: var(--warm-gray-30);
  --color-background-canvas-tint: var(--white);
  --color-background-canvas-dark: var(--black);
  --color-background-canvas-dark-tint: var(--cool-gray-90);
  --color-background-scrim: var(--black-a60);
  --color-background-scrim-dark: var(--black-a90);
  --color-background-new: var(--blue-50-a20);
  --color-background-new-active: var(--blue-50-a30);
  --color-background-new-on-dark: var(--blue-50-a30);
  --color-background-new-on-dark-active: var(--blue-50-a20);
  --color-element: var(--black-a90);
  --color-element-active: var(--black-a90);
  --color-element-on-dark: var(--white);
  --color-element-on-dark-active: var(--white-a70);
  --color-element-low-emphasis: var(--black-a60);
  --color-element-low-emphasis-active: var(--black-a90);
  --color-element-low-emphasis-on-dark: var(--white-a70);
  --color-element-low-emphasis-on-dark-active: var(--white-a70);
  --color-element-low-emphasis-shift: var(--black-a60);
  --color-element-low-emphasis-shift-active: var(--black-a90);
  --color-element-low-emphasis-stick: var(--black-a60);
  --color-element-low-emphasis-stick-active: var(--black-a90);
  --color-element-shift-on-dark-flip: var(--white);
  --color-element-shift-on-dark-flip-active: var(--white-a70);
  --color-element-solid: var(--system-gray-90);
  --color-element-solid-active: var(--system-gray-90);
  --color-element-solid-on-dark: var(--white);
  --color-element-solid-on-dark-active: var(--system-gray-40);
  --color-element-solid-low-emphasis: var(--system-gray-70);
  --color-element-solid-low-emphasis-active: var(--system-gray-90);
  --color-element-solid-low-emphasis-on-dark: var(--system-gray-40);
  --color-element-solid-low-emphasis-on-dark-active: var(--system-gray-50);
  --color-element-solid-low-emphasis-shift: var(--system-gray-70);
  --color-element-solid-low-emphasis-shift-active: var(--system-gray-90);
  --color-element-solid-low-emphasis-stick: var(--system-gray-70);
  --color-element-solid-low-emphasis-stick-active: var(--system-gray-90);
  --color-element-solid-shift-on-dark-flip: var(--white);
  --color-element-solid-shift-on-dark-flip-active: var(--system-gray-40);
  --color-element-solid-disabled: var(--system-gray-40);
  --color-element-solid-on-dark-disabled: var(--system-gray-70);
  --color-background-faint: var(--black-a08);
  --color-background-faint-active: var(--black-a15);
  --color-background-faint-on-dark: var(--white-a12);
  --color-background-faint-on-dark-active: var(--white-a06);
  --color-checked: var(--system-green-70);
  --color-checked-active: var(--system-green-80);
  --color-checked-tint: var(--system-green-30);
  --color-checked-on-dark: var(--system-green-40);
  --color-checked-on-dark-active: var(--system-green-50);
  --color-checked-tint-on-dark: var(--system-green-80);
  --color-checked-offset: var(--system-green-60);
  --color-checked-offset-active: var(--system-green-70);
  --color-checked-offset-on-dark: var(--system-green-50);
  --color-checked-offset-on-dark-active: var(--system-green-60);
  --color-background-none: var(--color-transparent);
  --color-background-none-hover: var(--color-transparent);
  --color-background-none-active: var(--black-a08);
  --color-background-none-on-dark: var(--color-transparent);
  --color-background-none-on-dark-hover: var(--color-transparent);
  --color-background-none-on-dark-active: var(--white-a12);
  --color-background-none-action-active: var(--blue-50-a20);
  --color-background-none-action-on-dark-active: var(--blue-50-a20);
  --color-background-none-tint-hover: var(--black-a08);
  --color-background-none-tint-active: var(--black-a12);
  --color-background-none-tint-on-dark-hover: var(--white-a18);
  --color-background-none-tint-on-dark-active: var(--white-a12);
  --color-background-none-tint-action-hover: var(--blue-50-a20);
  --color-background-none-tint-action-active: var(--blue-50-a30);
  --color-background-none-tint-action-on-dark-hover: var(--blue-50-a30);
  --color-background-none-tint-action-on-dark-active: var(--blue-50-a20);
  --color-disabled: var(--black-a30);
  --color-on-dark-disabled: var(--white-a40);
  --color-background-disabled: var(--black-a08);
  --color-background-on-dark-disabled: var(--white-a12);
  --color-border-focus: var(--teal-70);
  --color-background-focus: var(--teal-50-a30);
  --color-border-focus-on-dark: var(--teal-40);
  --color-background-focus-on-dark: var(--teal-50-a30);
  --color-shadow: var(--black-a30);
  --color-shadow-on-dark: var(--black-a60);
  --voyager-color-action: var(--blue-70);
  --voyager-color-action-hover: var(--blue-80);
  --voyager-color-action-active: var(--blue-90);
  --voyager-color-action-on-dark: var(--white);
  --voyager-color-action-on-dark-hover: var(--white);
  --voyager-color-action-on-dark-active: var(--white-a70);
  --voyager-color-action-stick: var(--blue-70);
  --voyager-color-action-stick-hover: var(--blue-80);
  --voyager-color-action-stick-active: var(--blue-90);
  --voyager-color-action-selected: var(--cool-gray-20);
  --voyager-color-action-on-dark-selected: var(--cool-gray-80);
  --memorialization-color-border: var(--lavender-80);
  --memorialization-color-label: var(--lavender-80);
  --memorialization-color-canvas: var(--lavender-10);
  --memorialization-color-border-on-dark: var(--lavender-40);
  --memorialization-color-background-label-on-dark: var(--lavender-40);
  --premium-color-brand: var(--amber-40);
  --premium-color-action: var(--amber-40);
  --premium-color-action-hover: var(--amber-50);
  --premium-color-action-active: var(--amber-60);
  --premium-color-text: var(--black-a90);
  --premium-color-text-active: var(--black-a90);
  --premium-color-icon: var(--black-a90);
  --premium-color-icon-active: var(--black-a90);
  --premium-color-plan-1: var(--green-70);
  --premium-color-plan-2: var(--cool-gray-70);
  --premium-color-plan-3: var(--mauve-70);
  --premium-color-plan-4: var(--mauve-80);
  --premium-color-plan-5: var(--copper-70);
  --premium-color-plan-6: var(--teal-70);
  --premium-color-inbug: var(--amber-60);
  --reactions-color-text-like: var(--blue-70);
  --reactions-color-text-like-on-dark: var(--blue-40);
  --reactions-color-text-celebrate: var(--green-70);
  --reactions-color-text-celebrate-on-dark: var(--green-40);
  --reactions-color-text-support: var(--lavender-70);
  --reactions-color-text-support-on-dark: var(--lavender-40);
  --reactions-color-text-love: var(--warm-red-70);
  --reactions-color-text-love-on-dark: var(--warm-red-40);
  --reactions-color-text-insightful: var(--amber-70);
  --reactions-color-text-insightful-on-dark: var(--amber-40);
  --reactions-color-text-curious: var(--mauve-70);
  --reactions-color-text-curious-on-dark: var(--mauve-40);
  --reactions-color-content-like: var(--blue-70);
  --reactions-color-content-like-on-dark: var(--blue-40);
  --reactions-color-content-celebrate: var(--green-70);
  --reactions-color-content-celebrate-on-dark: var(--green-40);
  --reactions-color-content-support: var(--lavender-70);
  --reactions-color-content-support-on-dark: var(--lavender-40);
  --reactions-color-content-love: var(--warm-red-70);
  --reactions-color-content-love-on-dark: var(--warm-red-40);
  --reactions-color-content-insightful: var(--amber-70);
  --reactions-color-content-insightful-on-dark: var(--amber-40);
  --reactions-color-content-curious: var(--mauve-70);
  --reactions-color-content-curious-on-dark: var(--mauve-40);
  --voyager-color-background-comment: var(--system-gray-20);
  --profile-video-color-border-ring-emphasized: linear-gradient(319.22deg,var(--purple-60) 15.03%,var(--blue-50) 84.54%);
  --profile-video-color-border-ring-deemphasized: var(--black-a30);
  --stories-color-element-content-consumption-indicator-track: var(--white-a70);
  --stories-color-element-content-consumption-indicator-fill: var(--white);
  --stories-color-element-accent-1-light: var(--system-red-40);
  --stories-color-element-accent-1-dark: var(--system-red-50);
  --stories-color-element-accent-1-darker: var(--system-red-70);
  --stories-color-element-accent-2-lighter: var(--warm-red-30);
  --stories-color-element-accent-2-light: var(--warm-red-40);
  --stories-color-element-accent-2-dark: var(--warm-red-50);
  --stories-color-element-accent-2-darker: var(--warm-red-70);
  --stories-color-element-accent-3-lighter: var(--system-green-30);
  --stories-color-element-accent-3-light: var(--system-green-40);
  --stories-color-element-accent-3-dark: var(--system-green-50);
  --stories-color-element-accent-3-darker: var(--system-green-70);
  --stories-color-element-accent-4-lighter: var(--blue-30);
  --stories-color-element-accent-4-light: var(--blue-40);
  --stories-color-element-accent-4-dark: var(--blue-50);
  --stories-color-element-accent-4-darker: var(--blue-70);
  --stories-color-element-accent-5-lighter: var(--purple-30);
  --stories-color-element-accent-5-light: var(--purple-40);
  --stories-color-element-accent-5-dark: var(--purple-50);
  --stories-color-element-accent-5-darker: var(--purple-70);
  --stories-color-element-accent-6-lighter: var(--cool-gray-30);
  --stories-color-element-accent-6-light: var(--cool-gray-40);
  --stories-color-element-accent-6-dark: var(--cool-gray-50);
  --stories-color-element-accent-6-darker: var(--cool-gray-70);
  --post-color-icon-photo: var(--blue-50);
  --post-color-icon-video: var(--green-50);
  --post-color-icon-event: var(--purple-50);
  --post-color-icon-article: var(--system-red-50);
  --voyager-color-background-slider-track: var(--system-gray-60);
  --voyager-color-background-container-tint-2: var(--cool-gray-20);
  --voyager-color-background-input-search: var(--cool-gray-20);
  --voyager-color-background-input-search-active: var(--cool-gray-10);
  --voyager-color-background-input-search-on-dark: var(--cool-gray-80);
  --voyager-color-background-input-search-on-dark-active: var(--cool-gray-90);
  --voyager-color-background-coachmark: var(--white);
  --voyager-color-background-coachmark-inner-dot: var(--purple-70);
  --voyager-color-background-coachmark-outer-dot: var(--purple-70-a15);
  --voyager-color-background-coachmark-on-dark: var(--cool-gray-90);
  --voyager-color-background-coachmark-inner-dot-on-dark: var(--purple-40);
  --voyager-color-background-coachmark-outer-dot-on-dark: var(--purple-40-a15);
  --voyager-color-icon-accent-person: var(--color-brand-accent-4);
  --voyager-color-icon-accent-person-on-dark: var(--color-brand-accent-4-on-dark);
  --voyager-color-icon-accent-company: var(--color-brand-accent-5);
  --voyager-color-icon-accent-company-on-dark: var(--color-brand-accent-5-on-dark);
  --voyager-color-icon-accent-school: var(--color-brand-accent-3);
  --voyager-color-icon-accent-school-on-dark: var(--color-brand-accent-3-on-dark);
  --voyager-color-icon-accent-group: var(--color-brand-accent-1);
  --voyager-color-icon-accent-group-on-dark: var(--color-brand-accent-3-on-dark);
  --voyager-color-icon-accent-hashtag: var(--color-brand-accent-4);
  --voyager-color-icon-accent-hashtag-on-dark: var(--color-brand-accent-4-on-dark);
  --voyager-color-icon-accent-event: var(--color-brand-accent-2);
  --voyager-color-icon-accent-event-on-dark: var(--color-brand-accent-2-on-dark);
  --voyager-color-icon-accent-publication: var(--color-brand-accent-4);
  --voyager-color-icon-accent-publication-on-dark: var(--color-brand-accent-4-on-dark);
  --voyager-color-qr-code: var(--black);
  --voyager-color-qr-code-background: var(--white);
  --voyager-color-skeleton: var(--black-a08);
  --voyager-color-skeleton-content: var(--black-a08);
  --voyager-color-skeleton-content-on-dark: var(--white-a12);
  --voyager-color-skeleton-content-shimmer: linear-gradient(-30deg,var(--color-transparent),var(--warm-gray-40-a50),var(--color-transparent));
  --voyager-color-skeleton-content-shimmer-on-dark: linear-gradient(-30deg,var(--color-transparent-on-dark),var(--white-a06),var(--color-transparent-on-dark));
  --voyager-color-background-scrim-tint: var(--white-a85);
  --voyager-color-background-attachment-hover: var(--white-a85);
  --voyager-color-background-attachment-pdf: var(--system-red-70);
  --voyager-color-background-attachment-pdf-on-dark: var(--system-red-40);
  --voyager-color-background-attachment-txt: var(--purple-70);
  --voyager-color-background-attachment-txt-on-dark: var(--purple-40);
  --voyager-color-background-attachment-psd: var(--blue-70);
  --voyager-color-background-attachment-psd-on-dark: var(--blue-40);
  --voyager-color-background-attachment-ai: var(--warm-red-70);
  --voyager-color-background-attachment-ai-on-dark: var(--warm-red-40);
  --voyager-color-background-attachment-ppt: var(--warm-red-70);
  --voyager-color-background-attachment-ppt-on-dark: var(--warm-red-40);
  --voyager-color-background-attachment-doc: var(--blue-80);
  --voyager-color-background-attachment-doc-on-dark: var(--blue-40);
  --voyager-color-background-attachment-xls: var(--system-green-70);
  --voyager-color-background-attachment-xls-on-dark: var(--system-green-40);
  --voyager-color-background-attachment-default: var(--cool-gray-70);
  --voyager-color-background-attachment-default-on-dark: var(--cool-gray-40);
  --voyager-color-element-loading: var(--black-a60);
  --voyager-color-element-loading-on-dark: var(--white-a70);
  --voyager-color-background-badge-notification: var(--system-red-70);
  --voyager-color-background-badge-new: var(--blue-70);
  --voyager-color-background-badge-selection: var(--system-green-70);
  --voyager-color-background-badge-presence: var(--system-green-70);
  --voyager-color-element-rating-input: var(--color-icon);
  --voyager-color-element-rating-input-on-dark: var(--color-icon-on-dark);
  --voyager-color-element-rating-display: var(--amber-60);
  --voyager-color-element-rating-display-on-dark: var(--amber-50);
  --identity-color-background-label: var(--amber-30);
  --identity-color-border-ring: linear-gradient(135deg,var(--warm-red-60) 25%,var(--amber-30) 75%);
  --identity-color-icon-create: var(--warm-red-60);
  --live-color-live-indicator: var(--system-red-70);
  --live-color-background-comment: var(--system-gray-20);
  --live-color-background-like: var(--white);
  --voyager-color-background-overlay: var(--black-a60);
  --voyager-color-background-overlay-hover: var(--black-a75);
  --voyager-color-background-overlay-active: var(--black-a90);
  --voyager-color-background-overlay-gradient-to-top: linear-gradient(0deg,var(--color-transparent) 0%,var(--voyager-color-background-overlay));
  --voyager-color-background-overlay-gradient-to-bottom: linear-gradient(180deg,var(--color-transparent) 0%,var(--voyager-color-background-overlay));
  --stories-color-background-overlay: linear-gradient(180deg,var(--black-a75),var(--black-a60) 25%,var(--black-a45) 50%,var(--black-a75));
  --messenger-color-background-input-message: var(--warm-gray-20);
  --messenger-color-background-input-message-active: var(--warm-gray-30);
  --growth-color-invitation-accent-groups: var(--smoke-60);
  --growth-color-invitation-accent-events: var(--teal-60);
  --growth-color-invitation-accent-newsletter: var(--green-60);
  --growth-color-invitation-accent-pages: var(--purple-60);
  --growth-color-onboarding-background-accent-1: var(--smoke-20);
  --growth-color-onboarding-background-accent-2: var(--camo-10);
  --data-viz-color-accent-1: var(--blue-60);
  --data-viz-color-accent-2: var(--teal-80);
  --data-viz-color-accent-3: var(--warm-red-60);
  --data-viz-color-accent-4: var(--warm-gray-80);
  --data-viz-color-accent-5: var(--lime-60);
  --data-viz-color-accent-6: var(--blue-80);
  --data-viz-color-accent-7: var(--amber-60);
  --data-viz-color-accent-8: var(--pink-80);
  --artdeco-reset-base-margin-zero: 0;
  --artdeco-reset-base-padding-zero: 0;
  --artdeco-reset-base-border-zero: 0;
  --artdeco-reset-base-font-size-hundred-percent: 100%;
  --artdeco-reset-base-font-weight-bold: bold;
  --artdeco-reset-base-font-style-italic: italic;
  --artdeco-reset-base-outline-zero: 0;
  --artdeco-reset-base-outline-none: none;
  --artdeco-reset-base-line-height-one: 1;
  --artdeco-reset-base-display-block: block;
  --artdeco-reset-base-list-style-none: none;
  --artdeco-reset-base-quotes-none: none;
  --artdeco-reset-base-vertical-align-baseline: baseline;
  --artdeco-reset-base-vertical-align-middle: middle;
  --artdeco-reset-base-background-transparent: transparent;
  --artdeco-reset-base-opacity-zero: 0;
  --artdeco-reset-base-top-zero: 0;
  --artdeco-reset-base-position-absolute: absolute;
  --artdeco-reset-base-text-decoration-none: none;
  --artdeco-reset-base-text-decoration-line-through: line-through;
  --artdeco-reset-base-border-collapse-collapse: collapse;
  --artdeco-reset-base-get-color-black: var(--black,#000);
  --artdeco-reset-base-background-color-ff9: #ff9;
  --artdeco-reset-base-border-spacing-zero: 0;
  --artdeco-reset-base-cursor-help: help;
  --artdeco-reset-base-content-none: none;
  --artdeco-reset-base-left-minus-hundred-px: -100px;
  --artdeco-reset-base-border-thickness-1-px: 1px;
  --artdeco-reset-base-border-style-dotted: dotted;
  --artdeco-reset-form-position-relative: relative;
  --artdeco-reset-form-vertical-align-middle: middle;
  --artdeco-reset-form-display-block: block;
  --artdeco-reset-form-black-90: rgba(0,0,0,0.9);
  --artdeco-reset-form-label-margin: 1.2rem 0 0.3rem;
  --artdeco-reset-form-label-opacity: 0.35;
  --artdeco-reset-form-webkit-appearance-textfield: textfield;
  --artdeco-reset-form-webkit-appearance-none: none;
  --artdeco-reset-form-height-auto: auto;
  --artdeco-reset-form-padding-top-point-seven-rem: 0.7rem;
  --artdeco-reset-form-rc-pointer-events: none;
  --artdeco-reset-form-rc-opacity: 0;
  --artdeco-reset-form-rc-margin: 1.6rem 0.2rem 0 0;
  --artdeco-reset-form-rc-position: absolute;
  --artdeco-reset-form-rc-before-after-content: " ";
  --artdeco-reset-checkbox-rc-after-content: "\2714";
  --artdeco-reset-form-rc-label-display-block: block;
  --artdeco-reset-form-rc-label-line-height-2-rem: 2rem;
  --artdeco-reset-form-rc-label-margin-bottom-zero: 0;
  --artdeco-reset-form-rc-label-padding-zero: 0;
  --artdeco-reset-form-rc-label-position-relative: relative;
  --artdeco-reset-form-rc-label-padding-left-2point8-rem: 2.8rem;
  --artdeco-reset-forms-input-transition-duration: 334ms;
  --artdeco-reset-forms-input-transition-property: border-color,box-shadow;
  --artdeco-reset-forms-input-box-shadow: none;
  --artdeco-reset-forms-input-border-radius: 0.2rem;
  --artdeco-reset-forms-input-border: 0.1rem solid rgba(0,0,0,0.6);
  --artdeco-reset-forms-input-padding: 0 1rem;
  --artdeco-reset-forms-input-width: 100%;
  --artdeco-reset-forms-input-height: 3.2rem;
  --artdeco-reset-forms-input-box-sizing: border-box;
  --artdeco-reset-forms-input-background-color: transparent;
  --artdeco-reset-forms-input-color: rgba(0,0,0,0.9);
  --artdeco-reset-forms-input-placeholder-color: rgba(0,0,0,0.6);
  --artdeco-reset-forms-input-blue: var(--blue-70,#0073b1);
  --artdeco-reset-forms-input-focus-box-shadow: 0 0 0 0.1rem var(--blue-70,#0073b1);
  --artdeco-reset-forms-input-disabled-hover-border-color: rgba(0,0,0,0.6);
  --artdeco-reset-forms-input-disabled-opacity: 0.45;
  --artdeco-reset-forms-input-error: var(--system-red-70,#d11124);
  --artdeco-reset-forms-font-weight: 400;
  --artdeco-reset-forms-font-size: 1.4rem;
  --artdeco-reset-forms-line-height: 1.42857;
  --artdeco-reset-forms-select-appearance-none: none;
  --artdeco-reset-forms-select-box-shadow-none: none;
  --artdeco-reset-forms-select-outline-zero: 0;
  --artdeco-reset-forms-select-height-3point2-rem: 3.2rem;
  --artdeco-reset-forms-select-background-transparent: transparent;
  --artdeco-reset-forms-select-position-relative: relative;
  --artdeco-reset-forms-select-zindex-two: 2;
  --artdeco-reset-forms-select-border-box: border-box;
  --artdeco-reset-forms-select-border-zero: 0;
  --artdeco-reset-forms-select-width-100-percent: 100%;
  --artdeco-reset-forms-select-border-radius-point2rem: 0.2rem;
  --artdeco-reset-forms-select-border: 0.1rem solid rgba(0,0,0,0.9);
  --artdeco-reset-forms-select-padding: 0 3.2rem 0 0.8rem;
  --artdeco-reset-forms-select-transition: box-shadow 0.15s;
  --artdeco-reset-forms-select-disabled-opacity: 0.45;
  --artdeco-reset-forms-select-hover-border-color: rgba(0,0,0,0.9);
  --artdeco-reset-forms-select-focus-border-color: var(--blue-70,#0073b1);
  --artdeco-reset-forms-select-focus-box-shadow: 0 0 0 0.1rem var(--blue-70,#0073b1);
  --artdeco-reset-typography-get-color-black-90: rgba(0,0,0,0.9);
  --artdeco-reset-typography-font-family-sans: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
  --artdeco-reset-typography-font-size-65-percent: 62.5%;
  --artdeco-reset-typography-font-weight-bold: 600;
  --artdeco-reset-typography-font-weight-light: 200;
  --artdeco-reset-typography-font-weight-normal: 400;
  --artdeco-reset-typography-font-family-inherit: inherit;
  --artdeco-reset-typography-font-style-italic: italic;
  --artdeco-reset-typography-font-style-normal: normal;
  --artdeco-reset-link-text-decoration-none: none;
  --artdeco-reset-link-font-weight-bold: 600;
  --artdeco-reset-link-background-color-transparent: transparent;
  --artdeco-reset-link-border-zero: 0;
  --artdeco-reset-link-color-blue7: var(--blue-70,#0073b1);
  --artdeco-reset-link-text-decoration-underline: underline;
  --artdeco-reset-link-color-blue8: var(--blue-70,#006097);
  --artdeco-reset-link-color-blue9: var(--blue-80,#004b7c);
  --artdeco-reset-link-color-purple7: var(--purple-70,#665ed0);
  --artdeco-reset-link-color-purple8: var(--purple-70,#544bc2);
  --artdeco-reset-link-color-purple9: var(--purple-80,#4034b0);
  --artdeco-typography-mono: SF Mono,Consolas,Roboto Mono,Noto Mono,Droid Mono,Fira Mono,Ubuntu Mono,Oxygen Mono,Lucida Console,Menlo,Monaco,monospace;
  --artdeco-typography-sans: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
  --artdeco-typography-serif: Noto Serif,Droid Serif,Georgia,serif;
  --artdeco-typography-ar: Arabic UI Display,Geeza Pro,Simplified Arabic,var(--artdeco-typography-sans);
  --artdeco-typography-ja: Meiryo,Yu Gothic,Hiragino Kaku Gothic Pro,Hiragino Sans,var(--artdeco-typography-sans);
  --artdeco-typography-ko: Malgun Gothic,Apple SD Gothic Neo,var(--artdeco-typography-sans);
  --artdeco-typography-th: Leelawadee,Thonburi,var(--artdeco-typography-sans);
  --artdeco-typography-zh: Microsoft Yahei,PingFang SC,PingFang TC,Hiragino Sans,Hiragino Kaku Gothic Pro,var(--artdeco-typography-sans);
  --artdeco-typography-hi: Kohinoor Devanagari,Mangal,var(--artdeco-typography-sans);
  --scaffold-layout-gutter: 1.2rem;
  --scaffold-layout-none-max-width: 576px;
  --scaffold-layout-md-max-width: 720px;
  --scaffold-layout-lg-max-width: 960px;
  --scaffold-layout-xl-max-width: 1128px;
  --scaffold-layout-list-detail-bottom-offset: 50px;
  -webkit-font-smoothing: subpixel-antialiased;
}

html {
  font-size: 62.5%;
}

body {
  direction: ltr;
  unicode-bidi: isolate;
  border: var(--artdeco-reset-base-border-zero);
  vertical-align: var(--artdeco-reset-base-vertical-align-baseline);
  background: var(--artdeco-reset-base-background-transparent);
  outline: var(--artdeco-reset-base-outline-zero);
  font-family: var(--artdeco-reset-typography-font-family-sans);
  font-weight: var(--artdeco-reset-typography-font-weight-normal);
  --artdeco-reset-typography_getFontSize: 1.6rem;
  font-size: var(--artdeco-reset-typography_getFontSize);
  --artdeco-reset-typography_getLineHeight: 1.5;
  line-height: var(--artdeco-reset-typography_getLineHeight);
  background-color: var(--color-background-canvas)!important;
  color: var(--color-text);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-y: scroll;
}


/*! CSS Used from: https://static-exp1.licdn.com/sc/h/a8hni7u3mxv1vq9qzu5g2hgo5 */
div,h1,h2,header,img,li,nav,span,strong,ul{margin:var(--artdeco-reset-base-margin-zero);padding:var(--artdeco-reset-base-padding-zero);border:var(--artdeco-reset-base-border-zero);font-size:var(--artdeco-reset-base-font-size-hundred-percent);vertical-align:var(--artdeco-reset-base-vertical-align-baseline);background:var(--artdeco-reset-base-background-transparent);}
h1,h2,img,span,strong{outline:var(--artdeco-reset-base-outline-zero);}
header,nav{display:var(--artdeco-reset-base-display-block);}
a{margin:var(--artdeco-reset-base-margin-zero);padding:var(--artdeco-reset-base-padding-zero);font-size:var(--artdeco-reset-base-font-size-hundred-percent);vertical-align:var(--artdeco-reset-base-vertical-align-baseline);background:var(--artdeco-reset-base-background-transparent);}
button{margin-bottom:0;}
a{text-decoration:var(--artdeco-reset-link-text-decoration-none);font-weight:var(--artdeco-reset-link-font-weight-bold);background-color:var(--artdeco-reset-link-background-color-transparent);border:var(--artdeco-reset-link-border-zero);}
a,a:visited{color:var(--artdeco-reset-link-color-blue7);}
a:focus,a:hover{text-decoration:var(--artdeco-reset-link-text-decoration-underline);color:var(--artdeco-reset-link-color-blue8);}
a:active{color:var(--artdeco-reset-link-color-blue9);}
a:visited{color:var(--artdeco-reset-link-color-purple7);}
a:visited:hover{color:var(--artdeco-reset-link-color-purple8);}
a:visited:active{color:var(--artdeco-reset-link-color-purple9);}
strong{font-weight:var(--artdeco-reset-typography-font-weight-bold);}
h1{font-weight:var(--artdeco-reset-typography-font-weight-normal);--artdeco-reset-typography_getFontSize:4rem;font-size:var(--artdeco-reset-typography_getFontSize);--artdeco-reset-typography_getLineHeight:1.2;line-height:var(--artdeco-reset-typography_getLineHeight);color:var(--artdeco-reset-typography-get-color-black-90);}
h2{font-weight:var(--artdeco-reset-typography-font-weight-normal);--artdeco-reset-typography_getFontSize:3.2rem;font-size:var(--artdeco-reset-typography_getFontSize);--artdeco-reset-typography_getLineHeight:1.25;line-height:var(--artdeco-reset-typography_getLineHeight);color:var(--artdeco-reset-typography-get-color-black-90);}
button{font-family:inherit;}
.LiIcon{display:inline-block;overflow:hidden;position:relative;}
.LiIcon[type$=-icon]>svg{vertical-align:top;}
.LiIcon>svg{display:block;margin:auto;width:100%;height:100%;}
.LiIcon svg[data-supported-dps="16x16"]{width:1.6rem;height:1.6rem;}
.LiIcon svg[data-supported-dps="24x24"]{width:2.4rem;height:2.4rem;}
.artdeco-card{border:none!important;box-shadow:var(--elevation-lined)!important;}
@media (-ms-high-contrast:active),(forced-colors:active){
  .artdeco-card{border:1px solid transparent!important;}
}
.artdeco-dropdown__content{border:none!important;box-shadow:var(--elevation-raised)!important;}
@media (-ms-high-contrast:active),(forced-colors:active){
  .artdeco-dropdown__content{border:1px solid transparent!important;}
}
a{font-weight:var(--font-weight-bold);}
a,a:hover{color:var(--color-action);}
a:active,a:focus,a:visited:focus{color:var(--color-action-active);}
a:visited,a:visited:hover{color:var(--color-text-link-visited);}
a:visited:active{color:var(--color-text-link-visited-active);}
img{color:var(--color-text);}
h1,h2{color:var(--color-text);}
.t-black{color:rgba(0,0,0,.9);}
.t-black--light{color:rgba(0,0,0,.6);}
.t-bold{font-weight:var(--artdeco-reset-typography-font-weight-bold);}
.t-normal{font-weight:var(--artdeco-reset-typography-font-weight-normal);}
.t-12{--artdeco-reset-typography_getFontSize:1.2rem;font-size:var(--artdeco-reset-typography_getFontSize);--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);}
.t-14{--artdeco-reset-typography_getFontSize:1.4rem;font-size:var(--artdeco-reset-typography_getFontSize);--artdeco-reset-typography_getLineHeight:1.42857;line-height:var(--artdeco-reset-typography_getLineHeight);}
.t-18{--artdeco-reset-typography_getFontSize:1.8rem;font-size:var(--artdeco-reset-typography_getFontSize);--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);}
.t-black{color:var(--color-text);}
.t-black--light{color:var(--color-text-low-emphasis);}
.artdeco-button,.artdeco-button:visited,.artdeco-button:visited:hover{background-color:var(--voyager-color-action);color:var(--color-text-shift-on-dark-flip);}
.artdeco-button:focus,.artdeco-button:hover{background-color:var(--voyager-color-action-hover);color:var(--color-text-shift-on-dark-flip);}
.artdeco-button:active{background-color:var(--voyager-color-action-active);color:var(--color-text-shift-on-dark-flip-active);}
.artdeco-button:disabled{background-color:var(--color-background-disabled);color:var(--color-text-disabled);}
.artdeco-button--secondary,.artdeco-button--secondary:visited,.artdeco-button--secondary:visited:hover{background-color:var(--color-background-none);color:var(--color-action);box-shadow:inset 0 0 0 1px var(--color-action);}
.artdeco-button--secondary:focus,.artdeco-button--secondary:hover{background-color:var(--color-background-none-tint-action-hover);color:var(--color-action);box-shadow:inset 0 0 0 2px var(--color-action);}
.artdeco-button--secondary:active{background-color:var(--color-background-none-tint-action-active);color:var(--color-action-active);box-shadow:inset 0 0 0 2px var(--color-action-active);}
.artdeco-button--secondary:disabled{background-color:var(--color-background-disabled);color:var(--color-text-disabled);box-shadow:inset 0 0 0 1px var(--color-transparent);}
.artdeco-button--muted.artdeco-button--secondary,.artdeco-button--muted.artdeco-button--secondary:visited,.artdeco-button--muted.artdeco-button--secondary:visited:hover{background-color:var(--color-background-none);color:var(--color-text-low-emphasis-shift);box-shadow:inset 0 0 0 1px var(--color-border);}
.artdeco-button--muted.artdeco-button--secondary:focus,.artdeco-button--muted.artdeco-button--secondary:hover{background-color:var(--color-background-none-tint-hover);color:var(--color-text-low-emphasis-shift);box-shadow:inset 0 0 0 2px var(--color-border);}
.artdeco-button--muted.artdeco-button--secondary:active{background-color:var(--color-background-none-active);color:var(--color-text-low-emphasis-shift-active);box-shadow:inset 0 0 0 2px var(--color-border-active);}
.artdeco-button--muted.artdeco-button--secondary:disabled{background-color:var(--color-background-disabled);color:var(--color-text-disabled);box-shadow:inset 0 0 0 1px var(--color-transparent);}
.artdeco-button--tertiary,.artdeco-button--tertiary:visited,.artdeco-button--tertiary:visited:hover{background-color:var(--color-background-none);color:var(--color-action);}
.artdeco-button--tertiary:focus,.artdeco-button--tertiary:hover{background-color:var(--color-background-none-tint-action-hover);color:var(--color-action);}
.artdeco-button--tertiary:active{background-color:var(--color-background-none-tint-action-active);color:var(--color-action-active);}
.artdeco-button--tertiary:disabled{background-color:var(--color-background-none);color:var(--color-text-disabled);}
.artdeco-button--muted.artdeco-button--tertiary,.artdeco-button--muted.artdeco-button--tertiary:visited,.artdeco-button--muted.artdeco-button--tertiary:visited:hover{background-color:var(--color-background-none);color:var(--color-text-low-emphasis-shift);}
.artdeco-button--muted.artdeco-button--tertiary:focus,.artdeco-button--muted.artdeco-button--tertiary:hover{background-color:var(--color-background-none-tint-hover);color:var(--color-text-low-emphasis-shift);}
.artdeco-button--muted.artdeco-button--tertiary:active{background-color:var(--color-background-none-tint-active);color:var(--color-text-low-emphasis-shift-active);}
.artdeco-button--muted.artdeco-button--tertiary:disabled{background-color:var(--color-background-none);color:var(--color-text-disabled);}
.artdeco-button,.artdeco-button--2{font-size:1.6rem;min-height:3.2rem;padding:.6rem 1.2rem;line-height:2rem;}
.artdeco-button--circle.artdeco-button{height:4rem;width:4rem;min-width:auto;}
.artdeco-button--1{font-size:1.4rem;min-height:2.4rem;padding:.2rem .8rem;line-height:2rem;}
.artdeco-button--circle.artdeco-button--1{height:3.2rem;width:3.2rem;min-width:auto;}
.artdeco-button--4{font-size:2rem;min-height:4.8rem;padding:1rem 2rem;line-height:2.8rem;}
.artdeco-button{transition-timing-function:cubic-bezier(.4,0,.2,1);transition-duration:167ms;align-items:center;border:none;border-radius:2px;box-sizing:border-box;cursor:pointer;font-family:inherit;font-weight:600;display:inline-flex;justify-content:center;min-width:6.4rem;max-width:480px;overflow:hidden;text-align:center;transition-property:background-color,box-shadow,color;vertical-align:middle;}
.artdeco-button:disabled{cursor:not-allowed;}
.artdeco-button:disabled *{pointer-events:none;}
.artdeco-button--tertiary{padding-left:8px;padding-right:8px;}
.artdeco-button--circle{border-radius:49.5%;padding:0;}
.artdeco-button--full{display:flex;flex:1;}
.artdeco-button__icon{flex-shrink:0;margin:0 4px 0 -2px;}
.artdeco-button--4 .artdeco-button__icon{margin-left:-4px;}
.artdeco-button--tertiary .artdeco-button__icon{margin-left:-2px;}
.artdeco-button--circle .artdeco-button__icon{margin:0;}
@media screen and (-ms-high-contrast:active),screen and (forced-colors:active){
  .artdeco-button--secondary,.artdeco-button--tertiary{background-color:transparent;}
  .artdeco-button--secondary:not(a),.artdeco-button--tertiary:not(a){color:ButtonText!important;}
  .artdeco-button--secondary:active,.artdeco-button--secondary:focus,.artdeco-button--secondary:hover,.artdeco-button--tertiary:active,.artdeco-button--tertiary:focus,.artdeco-button--tertiary:hover{border:1px solid ButtonText!important;box-shadow:inset 0 0 0 1px ButtonText!important;}
  .artdeco-button--secondary:disabled,.artdeco-button--secondary:disabled:active,.artdeco-button--secondary:disabled:focus,.artdeco-button--secondary:disabled:hover,.artdeco-button--tertiary:disabled,.artdeco-button--tertiary:disabled:active,.artdeco-button--tertiary:disabled:focus,.artdeco-button--tertiary:disabled:hover{color:GrayText!important;border:1px solid GrayText!important;box-shadow:none;outline:none;}
  .artdeco-button--tertiary{border:1px solid transparent;}
  .artdeco-button--secondary{border:1px solid ButtonText!important;}
}
.artdeco-button{min-width:0;}
.artdeco-button,.artdeco-button--2{border-radius:var(--corner-radius-large)!important;padding-left:1.6rem!important;padding-right:1.6rem!important;}
.artdeco-button--1{border-radius:var(--corner-radius-large)!important;padding-left:1.2rem!important;padding-right:1.2rem!important;}
.artdeco-button--4{border-radius:var(--corner-radius-xlarge)!important;}
.artdeco-button--4{padding-left:2.4rem!important;padding-right:2.4rem!important;}
.artdeco-button--tertiary{border-radius:var(--corner-radius-small)!important;padding-left:.8rem!important;padding-right:.8rem!important;}
.artdeco-button--circle{border-radius:var(--corner-radius-full)!important;padding:0!important;}
.artdeco-button--full{max-width:100%!important;flex-grow:1!important;}
.artdeco-card{box-shadow:0 0 0 1px rgba(0,0,0,.15),0 2px 3px rgba(0,0,0,.2);transition:box-shadow 83ms;background-color:var(--white,#fff);border-radius:2px;overflow:hidden;position:relative;}
@media screen and (-ms-high-contrast:active){
  .artdeco-card{border:1px solid activeborder;}
}
.artdeco-card{background-color:var(--color-background-container)!important;border-radius:var(--corner-radius-medium)!important;}
.scaffold-layout .artdeco-card{border-radius:0!important;}
@media screen and (min-width:576px){
  .scaffold-layout--reflow .artdeco-card{border-radius:var(--corner-radius-medium)!important;}
}
@media (-ms-high-contrast:active),(forced-colors:active){
  .artdeco-card{border:1px solid transparent!important;}
}
.artdeco-dropdown{box-sizing:border-box;cursor:pointer;display:block;margin:0;padding:0;position:relative;}
.artdeco-dropdown__content{box-shadow:0 0 0 1px rgba(0,0,0,.15);background-color:var(--white,#fff);background-clip:padding-box;border-radius:2px;box-shadow:0 0 0 1px rgba(0,0,0,.15),0 6px 9px rgba(0,0,0,.2);transition:box-shadow 83ms;transition:visibility 0s linear 167ms,opacity 167ms cubic-bezier(.4,0,1,1) 0s,z-index 0s linear 167ms;padding:4px 0;opacity:0;visibility:hidden;z-index:-1;display:inline-block;position:absolute;min-width:168px;max-width:26.4rem;max-height:54.8rem;box-sizing:border-box;left:0;top:calc(100% + 8px);}
@media screen and (-ms-high-contrast:active){
  .artdeco-dropdown__content{border:1px solid activeborder;}
}
.artdeco-dropdown__content--placement-bottom{top:calc(100% + 8px);bottom:auto;}
.artdeco-dropdown__content--justification-right{left:auto;right:0;}
.artdeco-dropdown__content--has-arrow:after,.artdeco-dropdown__content--has-arrow:before{position:absolute;border:0 solid transparent;content:"";height:0;width:0;}
.artdeco-dropdown__content--arrow-right:after,.artdeco-dropdown__content--arrow-right:before{left:calc(100% - 24px);}
.artdeco-dropdown__content--placement-bottom.artdeco-dropdown__content--has-arrow:after{border-right-width:8px;border-bottom:8px solid var(--white,#fff);border-left-width:8px;top:-8px;margin-left:-9px;}
.artdeco-dropdown__content--placement-bottom.artdeco-dropdown__content--has-arrow:before{border-right-width:9px;border-bottom:9px solid rgba(0,0,0,.1);border-left-width:9px;top:-9px;margin-left:-10px;}
.artdeco-dropdown__content{background-color:var(--color-background-container)!important;border-radius:var(--corner-radius-medium)!important;}
.artdeco-dropdown__content:after,.artdeco-dropdown__content:before{border:none!important;content:none!important;}
.artdeco-dropdown__content--placement-bottom.artdeco-dropdown__content--justification-right{border-radius:var(--point-top-right)!important;}
.artdeco-hoverable-trigger{position:relative;}
.artdeco-hoverable-trigger--is-hoverable:before{content:"";display:none;position:absolute;}
.artdeco-hoverable-trigger--is-hoverable:hover:before{display:block;}
.artdeco-hoverable-trigger--content-placed-top:before{bottom:calc(100% - 1px);width:100%;height:14px;}
.artdeco-pill{transition-timing-function:cubic-bezier(.4,0,.2,1);transition-duration:167ms;align-items:center;background-color:transparent;border:none;box-sizing:border-box;font-weight:600;line-height:1;display:inline-flex;font-family:inherit;justify-content:center;position:relative;transition-property:background-color,box-shadow,color;}
@media (-ms-high-contrast:active),(forced-colors:active){
  .artdeco-pill{border:1px solid ButtonHighlight;}
  .artdeco-pill li-icon>svg{forced-color-adjust:auto;}
}
.artdeco-pill--toggle,.artdeco-pill--toggle:visited{background-color:var(--color-background-none);}
.artdeco-pill--toggle:hover{background-color:var(--color-background-none-tint-hover);}
.artdeco-pill--toggle:active{background-color:var(--color-background-none-active);}
.artdeco-pill--toggle.artdeco-pill--selected{background-color:var(--color-checked);}
@media (-ms-high-contrast:active),(forced-colors:active){
  .artdeco-pill--toggle.artdeco-pill--selected{forced-color-adjust:none;background-color:Highlight;}
}
.artdeco-pill--toggle.artdeco-pill--selected:hover{background-color:var(--color-checked);}
.artdeco-pill--toggle.artdeco-pill--selected:active{background-color:var(--color-checked-active);}
.artdeco-pill--toggle,.artdeco-pill--toggle:hover,.artdeco-pill--toggle:visited{color:var(--color-text-low-emphasis-shift);}
.artdeco-pill--toggle:active{color:var(--color-text-low-emphasis-shift-active);}
.artdeco-pill--toggle.artdeco-pill--selected{color:var(--color-text-shift-on-dark-flip);}
@media (-ms-high-contrast:active),(forced-colors:active){
  .artdeco-pill--toggle.artdeco-pill--selected{forced-color-adjust:none;color:HighlightText;}
}
.artdeco-pill--toggle.artdeco-pill--selected:hover{color:var(--color-text-shift-on-dark-flip);}
.artdeco-pill--toggle.artdeco-pill--selected:active{color:var(--color-text-shift-on-dark-flip-active);}
.artdeco-pill--toggle,.artdeco-pill--toggle:visited{box-shadow:inset 0 0 0 1px var(--color-border-low-emphasis);}
.artdeco-pill--toggle:hover{box-shadow:inset 0 0 0 2px var(--color-border-low-emphasis);}
.artdeco-pill--toggle:active{box-shadow:inset 0 0 0 2px var(--color-border);}
.artdeco-pill--toggle.artdeco-pill--selected{box-shadow:none;}
.artdeco-pill--toggle.artdeco-pill--selected:hover{box-shadow:inset 0 0 0 1px var(--color-checked);}
.artdeco-pill--toggle.artdeco-pill--selected:active{box-shadow:none;}
.artdeco-pill--3{border-radius:1.99rem;font-size:1.6rem;padding:8px 12px;}
.artdeco-pill--toggle .artdeco-pill__icon{margin:0 -2px 0 4px;}
.artdeco-pill__icon{height:16px;overflow:hidden;width:16px;}
*,:after,:before{box-sizing:inherit;}
a[href],button{touch-action:manipulation;}
button{background:none;border:none;padding:0;cursor:pointer;}
.EntityPhoto-circle-3{width:48px;height:48px;box-sizing:border-box;background-clip:content-box;border:2px solid transparent;border-radius:49.9%;}
.hoverable-link-text.hoverable-link-text:hover{color:var(--color-action);text-decoration:underline;}
.mb2{margin-bottom:8px!important;}
.mr2{margin-right:8px!important;}
.mt4{margin-top:16px!important;}
.mb4{margin-bottom:16px!important;}
.pb2{padding-bottom:8px!important;}
.ph4{padding-left:16px!important;padding-right:16px!important;}
.pt4{padding-top:16px!important;}
.align-items-center{align-items:center!important;}
.display-flex{display:flex!important;}
.full-height{height:100%!important;}
.flex-column{flex-direction:column!important;}
.flex-wrap{flex-wrap:wrap!important;}
.flex-grow-1{flex-grow:1!important;}
.justify-center{justify-content:center!important;}
.relative{position:relative!important;}
.absolute{position:absolute!important;}
.v-align-bottom{vertical-align:bottom!important;}
.visually-hidden{display:block!important;border:0!important;clip:rect(0 0 0 0)!important;height:1px!important;margin:-1px!important;overflow:hidden!important;padding:0!important;position:absolute!important;white-space:nowrap!important;width:1px!important;}
.text-align-right{text-align:right!important;}
.break-words{overflow-wrap:break-word!important;word-wrap:break-word!important;word-break:break-word!important;}
[class*=EntityPhoto-circle]{background-color:var(--color-background-container);color:var(--color-text);border:none;box-shadow:none;}
[class*=EntityPhoto-circle]{border-radius:var(--corner-radius-full)!important;}
.artdeco-card{overflow:visible;}
/*! CSS Used from: https://static-exp1.licdn.com/sc/h/brbpv1hsmmy16qgo3l9h5gujv */
.ivm-view-attr__img--centered{background-position:50%;background-size:cover;}
.ivm-view-attr__img-wrapper--use-img-tag .ivm-view-attr__img--centered{object-position:center;object-fit:cover;}
.content-analytics-entry-point .analytics-entry-point:before{content:"";display:block;border-top:none;}
.content-analytics-entry-point .analytics-entry-point .icon-and-text-container{display:flex;align-items:center;padding:8px 16px;}
.content-analytics-entry-point .analytics-entry-point .icon-and-text-container li-icon{height:24px;margin-right:8px;}
.comment-button.artdeco-button--tertiary.artdeco-button--4{padding-left:8px!important;padding-right:8px!important;}
.visually-hidden{position:absolute!important;height:1px;width:1px;overflow:hidden;clip:rect(1px 1px 1px 1px);clip:rect(1px,1px,1px,1px);white-space:nowrap;}
.entity-hovercard__a11y-trigger{flex-shrink:0;width:0;height:0;opacity:0;overflow:hidden;vertical-align:middle;}
.entity-hovercard__a11y-trigger:focus{width:10px;height:10px;opacity:1;margin-left:4px;}
.entity-hovercard__a11y-trigger:after{display:block;content:"";width:0;height:0;border:5px solid transparent;border-top:5px solid var(--color-icon-active);position:relative;top:3px;}
.presence-entity{display:flex;position:relative;}
.presence-entity--size-3{width:48px;height:48px;}
.presence-entity__image{background-size:cover;}
.presence-entity__indicator{position:absolute;box-shadow:0 0 0 2px var(--color-background-container);}
.presence-entity__indicator--size-3{bottom:2px;right:2px;}
.presence-entity__indicator--size-3{bottom:0;right:0;}
.presence-indicator{border-radius:50%;background:var(--color-background-container);flex-shrink:0;transition:background 167ms ease-in-out;box-sizing:border-box;animation:fade-in 167ms ease-in;}
.presence-indicator--size-3{width:12px;height:12px;}
.presence-indicator--is-online{background:var(--color-signal-positive);}
.reactions-react-button{overflow:visible;}
.reactions-react-button .artdeco-button__text{display:flex;}
.reactions-react-button{display:inline-flex;}
.react-button__trigger.artdeco-button--tertiary.artdeco-button--4{padding-left:8px!important;padding-right:8px!important;}
.reactions-menu__trigger{transform:rotate(180deg);min-width:0;height:0!important;opacity:0!important;max-width:0!important;padding:0!important;width:0!important;}
.reactions-icon{display:inline-block;background-repeat:no-repeat;background-size:100%;vertical-align:middle;}
.reactions-icon--stacked{background-color:var(--color-background-container);border-radius:50%;margin:0 0 0 -4px;}
.reactions-icon__consumption--small{height:16px!important;width:16px!important;}
.reshare-button.artdeco-button--tertiary.artdeco-button--4{padding-left:8px!important;padding-right:8px!important;}
.social-details-social-counts{--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);display:flex;align-items:flex-start;overflow:auto;margin:0 16px;padding:8px 0;border-bottom:1px solid var(--color-border-faint);}
.social-details-social-counts--with-social-proof{align-items:flex-start;justify-content:flex-end;overflow:hidden;}
.social-details-social-counts__item{list-style:none;margin-right:4px;color:var(--color-text-low-emphasis);}
.social-details-social-counts__item.social-details-social-counts__item{display:flex;align-items:center;}
.social-details-social-counts__item--with-social-proof{flex-shrink:0;}
.social-details-social-counts__item--with-social-proof:last-child{margin-right:0;}
.social-details-social-counts__item-text--with-social-proof{--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);}
.social-details-social-counts__count-value{--artdeco-reset-typography_getLineHeight:1.5;line-height:var(--artdeco-reset-typography_getLineHeight);}
.social-details-social-counts__count-value:disabled{cursor:default;}
.social-details-social-counts__reactions .hoverable-link-text:hover{text-decoration:none;}
.social-details-social-counts__reactions--with-social-proof{flex-grow:1;}
.social-details-social-counts__social-proof-container{--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);display:flex;flex-grow:1;align-items:center;overflow:hidden;flex-wrap:wrap;max-height:1.3333333em;margin-right:24px;}
.social-details-social-counts__social-proof-fallback-number{--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);overflow:hidden;width:100px;float:left;text-align:left;padding-left:4px;}
.social-details-social-counts__social-proof-fallback-number:hover{text-decoration:underline;}
.social-details-social-counts__social-proof-text{--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);float:left;margin-left:-100px;background-color:var(--color-background-container);padding-left:4px;}
.social-details-social-counts__social-proof-text:hover{text-decoration:underline;}
.send-privately-button.artdeco-button--tertiary.artdeco-button--4{padding-left:8px!important;padding-right:8px!important;}
/*! CSS Used from: https://static-exp1.licdn.com/sc/h/cgwlodczp806egetlu2jbgj2s */
.pv-recent-activity-detail__pills{font-size:0;}
.pv-recent-activity-detail__pill .artdeco-pill__icon{display:none;}
/*! CSS Used from: https://static-exp1.licdn.com/sc/h/2ukjzd8u0223pj3og6y5mqnrk */
.feed-shared-inline-show-more-text{position:relative;max-width:928px;--artdeco-reset-typography_getLineHeight:1.42857;display:block;line-height:2rem!important;max-height:6rem;margin:0 16px;overflow:hidden;}
.feed-shared-inline-show-more-text *{line-height:inherit!important;}
.feed-shared-inline-show-more-text.feed-shared-inline-show-more-text--5-lines{display:block;line-height:2rem!important;max-height:10rem;overflow:hidden;}
.feed-shared-inline-show-more-text.feed-shared-inline-show-more-text--5-lines *{line-height:inherit!important;}
.feed-shared-inline-show-more-text .feed-shared-inline-show-more-text__see-more-less-toggle{background-color:var(--color-background-container);line-height:inherit;position:absolute;bottom:0;right:0;margin-bottom:0;padding-left:8px;}
.feed-shared-inline-show-more-text .see-more:hover{color:var(--color-action);text-decoration:underline;}
.feed-shared-actor__container-link:focus,.feed-shared-actor__container-link:hover{text-decoration:none;}
.feed-shared-actor__name{vertical-align:top;}
.feed-shared-social-action-bar{align-items:center;box-sizing:border-box;display:flex;justify-content:flex-start;margin:0;min-height:40px;padding:4px 8px;flex-wrap:wrap;}
.feed-shared-social-action-bar .social-actions-button{min-height:3.2rem;line-height:2.8rem;}
.feed-shared-social-action-bar .artdeco-button__text{font-size:1.4rem;}
.feed-shared-social-action-bar--full-width{display:grid;grid-auto-flow:column;justify-content:initial;column-gap:var(--spacing-half-x);padding:4px var(--spacing-one-and-a-half-x);}
.feed-shared-social-action-bar--full-width .social-actions-button{width:100%!important;}
.scaffold-layout--reflow .feed-shared-social-action-bar__action-button{flex:1 1 auto;}
.scaffold-layout--reflow .feed-shared-social-action-bar__action-button .social-actions-button{width:100%;}
@media screen and (max-width:427px){
  .scaffold-layout--reflow .feed-shared-social-action-bar__action-button .comment-button .artdeco-button__text,.scaffold-layout--reflow .feed-shared-social-action-bar__action-button .react-button__text,.scaffold-layout--reflow .feed-shared-social-action-bar__action-button .reshare-button .artdeco-button__text,.scaffold-layout--reflow .feed-shared-social-action-bar__action-button .send-privately-button .artdeco-button__text{display:none;}
  .scaffold-layout--reflow .feed-shared-social-action-bar__action-button li-icon{margin:0;}
}
@media screen and (min-width:768px){
  .scaffold-layout--reflow .feed-shared-social-action-bar__action-button{flex:0 1 auto;}
  .scaffold-layout--reflow .feed-shared-social-action-bar__action-button .social-actions-button{width:initial;}
}
.feed-shared-actor{flex-wrap:nowrap;padding:12px 16px 0;margin-bottom:8px;align-items:center;}
.feed-shared-actor--with-control-menu{padding-right:40px;}
.feed-shared-actor__meta{flex-grow:1;flex-basis:0;margin-left:8px;overflow:hidden;}
.feed-shared-actor__image{flex-shrink:0;}
.feed-shared-actor__container{left:0;right:0;top:0;bottom:0;}
.feed-shared-actor__container-link{overflow:hidden;}
.feed-shared-update-v2--minimal-padding .feed-shared-actor__container-link{margin-right:12px;}
.feed-shared-actor__avatar-image.feed-shared-actor__avatar-image{background-size:contain;background-repeat:no-repeat;}
.ivm-view-attr__img-wrapper--use-img-tag .feed-shared-actor__avatar-image.feed-shared-actor__avatar-image{object-fit:contain;}
.feed-shared-actor__description,.feed-shared-actor__sub-description{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;}
.feed-shared-actor__name{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:inline-block;}
.feed-shared-update-v2--minimal-padding .feed-shared-actor__title{display:flex;}
.feed-shared-update-v2__control-menu{top:4px;right:8px;}
.feed-shared-update-v2--minimal-padding .feed-shared-update-v2__control-menu{right:12px;}
.feed-shared-control-menu__content{max-width:355px;width:90vw;right:-8px;z-index:6;}
.feed-shared-image__container{height:0;width:100%;display:block;position:relative;background-color:var(--color-background-container);}
.feed-shared-image__image-link{top:0;left:0;width:100%;height:100%;position:absolute;display:block;cursor:pointer;}
.feed-shared-image__image-link .ivm-image-view-model,.feed-shared-image__image-link .ivm-view-attr__img-wrapper{width:100%;height:100%;}
.feed-shared-image__image{width:100%;height:100%;}
.feed-shared-update-v2{padding:0;margin:0 0 8px;}
.feed-shared-update-v2__commentary span a{position:relative;}
.feed-shared-update-v2__commentary{--artdeco-reset-typography_getFontSize:1.4rem;font-size:var(--artdeco-reset-typography_getFontSize);--artdeco-reset-typography_getLineHeight:1.42857;line-height:var(--artdeco-reset-typography_getLineHeight);color:var(--color-text-low-emphasis);}
.feed-shared-update-v2--minimal-padding .feed-shared-update-v2__commentary{color:var(--color-text);}
.feed-shared-update-v2__description-wrapper+.feed-shared-update-v2__content{margin-top:8px;}
.occludable-update-hint{min-height:140px;}
/*! CSS Used from: https://static-exp1.licdn.com/sc/h/ae7comv4jih6pdcpqlwske3xf */
.pv-recent-activity-detail__outlet-container{position:relative;}
.pv-recent-activity-detail__core-rail{position:relative;}
/*! CSS Used keyframes */
@keyframes fade-in{0%{opacity:0;}to{opacity:1;}}
