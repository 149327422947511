div,h2,img,li,span,strong,ul{margin:var(--artdeco-reset-base-margin-zero);padding:var(--artdeco-reset-base-padding-zero);border:var(--artdeco-reset-base-border-zero);font-size:var(--artdeco-reset-base-font-size-hundred-percent);vertical-align:var(--artdeco-reset-base-vertical-align-baseline);background:var(--artdeco-reset-base-background-transparent);}
h2,img,span,strong{outline:var(--artdeco-reset-base-outline-zero);}
a{margin:var(--artdeco-reset-base-margin-zero);padding:var(--artdeco-reset-base-padding-zero);font-size:var(--artdeco-reset-base-font-size-hundred-percent);vertical-align:var(--artdeco-reset-base-vertical-align-baseline);background:var(--artdeco-reset-base-background-transparent);}
button{margin-bottom:0;}
a{text-decoration:var(--artdeco-reset-link-text-decoration-none);font-weight:var(--artdeco-reset-link-font-weight-bold);background-color:var(--artdeco-reset-link-background-color-transparent);border:var(--artdeco-reset-link-border-zero);}
a,a:visited{color:var(--artdeco-reset-link-color-blue7);}
a:focus,a:hover{text-decoration:var(--artdeco-reset-link-text-decoration-underline);color:var(--artdeco-reset-link-color-blue8);}
a:active{color:var(--artdeco-reset-link-color-blue9);}
a:visited{color:var(--artdeco-reset-link-color-purple7);}
a:visited:hover{color:var(--artdeco-reset-link-color-purple8);}
a:visited:active{color:var(--artdeco-reset-link-color-purple9);}
strong{font-weight:var(--artdeco-reset-typography-font-weight-bold);}
h2{font-weight:var(--artdeco-reset-typography-font-weight-normal);--artdeco-reset-typography_getFontSize:3.2rem;font-size:var(--artdeco-reset-typography_getFontSize);--artdeco-reset-typography_getLineHeight:1.25;line-height:var(--artdeco-reset-typography_getLineHeight);color:var(--artdeco-reset-typography-get-color-black-90);}
button{font-family:inherit;}
.LiIcon{display:inline-block;overflow:hidden;position:relative;}
.LiIcon[type$=-icon]>svg{vertical-align:top;}
.LiIcon>svg{display:block;margin:auto;width:100%;height:100%;}
.LiIcon svg[data-supported-dps="16x16"]{width:1.6rem;height:1.6rem;}
.LiIcon svg[data-supported-dps="24x24"]{width:2.4rem;height:2.4rem;}
.artdeco-card{border:none!important;box-shadow:var(--elevation-lined)!important;}
@media (-ms-high-contrast:active),(forced-colors:active){
  .artdeco-card{border:1px solid transparent!important;}
}
.artdeco-dropdown__content{border:none!important;box-shadow:var(--elevation-raised)!important;}
@media (-ms-high-contrast:active),(forced-colors:active){
  .artdeco-dropdown__content{border:1px solid transparent!important;}
}
a{font-weight:var(--font-weight-bold);}
a,a:hover{color:var(--color-action);}
a:active,a:focus,a:visited:focus{color:var(--color-action-active);}
a:visited,a:visited:hover{color:var(--color-text-link-visited);}
a:visited:active{color:var(--color-text-link-visited-active);}
img{color:var(--color-text);}
h2{color:var(--color-text);}
.t-black{color:rgba(0,0,0,.9);}
.t-black--light{color:rgba(0,0,0,.6);}
.t-bold{font-weight:var(--artdeco-reset-typography-font-weight-bold);}
.t-normal{font-weight:var(--artdeco-reset-typography-font-weight-normal);}
.t-12{--artdeco-reset-typography_getFontSize:1.2rem;font-size:var(--artdeco-reset-typography_getFontSize);--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);}
.t-14{--artdeco-reset-typography_getFontSize:1.4rem;font-size:var(--artdeco-reset-typography_getFontSize);--artdeco-reset-typography_getLineHeight:1.42857;line-height:var(--artdeco-reset-typography_getLineHeight);}
.t-black{color:var(--color-text);}
.t-black--light{color:var(--color-text-low-emphasis);}
.artdeco-button,.artdeco-button:visited,.artdeco-button:visited:hover{background-color:var(--voyager-color-action);color:var(--color-text-shift-on-dark-flip);}
.artdeco-button:focus,.artdeco-button:hover{background-color:var(--voyager-color-action-hover);color:var(--color-text-shift-on-dark-flip);}
.artdeco-button:active{background-color:var(--voyager-color-action-active);color:var(--color-text-shift-on-dark-flip-active);}
.artdeco-button:disabled{background-color:var(--color-background-disabled);color:var(--color-text-disabled);}
.artdeco-button--tertiary,.artdeco-button--tertiary:visited,.artdeco-button--tertiary:visited:hover{background-color:var(--color-background-none);color:var(--color-action);}
.artdeco-button--tertiary:focus,.artdeco-button--tertiary:hover{background-color:var(--color-background-none-tint-action-hover);color:var(--color-action);}
.artdeco-button--tertiary:active{background-color:var(--color-background-none-tint-action-active);color:var(--color-action-active);}
.artdeco-button--tertiary:disabled{background-color:var(--color-background-none);color:var(--color-text-disabled);}
.artdeco-button--muted.artdeco-button--tertiary,.artdeco-button--muted.artdeco-button--tertiary:visited,.artdeco-button--muted.artdeco-button--tertiary:visited:hover{background-color:var(--color-background-none);color:var(--color-text-low-emphasis-shift);}
.artdeco-button--muted.artdeco-button--tertiary:focus,.artdeco-button--muted.artdeco-button--tertiary:hover{background-color:var(--color-background-none-tint-hover);color:var(--color-text-low-emphasis-shift);}
.artdeco-button--muted.artdeco-button--tertiary:active{background-color:var(--color-background-none-tint-active);color:var(--color-text-low-emphasis-shift-active);}
.artdeco-button--muted.artdeco-button--tertiary:disabled{background-color:var(--color-background-none);color:var(--color-text-disabled);}
.artdeco-button,.artdeco-button--2{font-size:1.6rem;min-height:3.2rem;padding:.6rem 1.2rem;line-height:2rem;}
.artdeco-button--circle.artdeco-button{height:4rem;width:4rem;min-width:auto;}
.artdeco-button--1{font-size:1.4rem;min-height:2.4rem;padding:.2rem .8rem;line-height:2rem;}
.artdeco-button--circle.artdeco-button--1{height:3.2rem;width:3.2rem;min-width:auto;}
.artdeco-button--4{font-size:2rem;min-height:4.8rem;padding:1rem 2rem;line-height:2.8rem;}
.artdeco-button{transition-timing-function:cubic-bezier(.4,0,.2,1);transition-duration:167ms;align-items:center;border:none;border-radius:2px;box-sizing:border-box;cursor:pointer;font-family:inherit;font-weight:600;display:inline-flex;justify-content:center;min-width:6.4rem;max-width:480px;overflow:hidden;text-align:center;transition-property:background-color,box-shadow,color;vertical-align:middle;}
.artdeco-button:disabled{cursor:not-allowed;}
.artdeco-button:disabled *{pointer-events:none;}
.artdeco-button--tertiary{padding-left:8px;padding-right:8px;}
.artdeco-button--circle{border-radius:49.5%;padding:0;}
.artdeco-button__icon{flex-shrink:0;margin:0 4px 0 -2px;}
.artdeco-button--4 .artdeco-button__icon{margin-left:-4px;}
.artdeco-button--tertiary .artdeco-button__icon{margin-left:-2px;}
.artdeco-button--circle .artdeco-button__icon{margin:0;}
@media screen and (-ms-high-contrast:active),screen and (forced-colors:active){
  .artdeco-button--tertiary{background-color:transparent;}
  .artdeco-button--tertiary:not(a){color:ButtonText!important;}
  .artdeco-button--tertiary:active,.artdeco-button--tertiary:focus,.artdeco-button--tertiary:hover{border:1px solid ButtonText!important;box-shadow:inset 0 0 0 1px ButtonText!important;}
  .artdeco-button--tertiary:disabled,.artdeco-button--tertiary:disabled:active,.artdeco-button--tertiary:disabled:focus,.artdeco-button--tertiary:disabled:hover{color:GrayText!important;border:1px solid GrayText!important;box-shadow:none;outline:none;}
  .artdeco-button--tertiary{border:1px solid transparent;}
}
.artdeco-button{min-width:0;}
.artdeco-button,.artdeco-button--2{border-radius:var(--corner-radius-large)!important;padding-left:1.6rem!important;padding-right:1.6rem!important;}
.artdeco-button--1{border-radius:var(--corner-radius-large)!important;padding-left:1.2rem!important;padding-right:1.2rem!important;}
.artdeco-button--4{border-radius:var(--corner-radius-xlarge)!important;}
.artdeco-button--4{padding-left:2.4rem!important;padding-right:2.4rem!important;}
.artdeco-button--tertiary{border-radius:var(--corner-radius-small)!important;padding-left:.8rem!important;padding-right:.8rem!important;}
.artdeco-button--circle{border-radius:var(--corner-radius-full)!important;padding:0!important;}
.artdeco-card{box-shadow:0 0 0 1px rgba(0,0,0,.15),0 2px 3px rgba(0,0,0,.2);transition:box-shadow 83ms;background-color:var(--white,#fff);border-radius:2px;overflow:hidden;position:relative;}
@media screen and (-ms-high-contrast:active){
  .artdeco-card{border:1px solid activeborder;}
}
.artdeco-card{background-color:var(--color-background-container)!important;border-radius:var(--corner-radius-medium)!important;}
.scaffold-layout .artdeco-card{border-radius:0!important;}
@media screen and (min-width:576px){
  .scaffold-layout--reflow .artdeco-card{border-radius:var(--corner-radius-medium)!important;}
}
@media (-ms-high-contrast:active),(forced-colors:active){
  .artdeco-card{border:1px solid transparent!important;}
}
.artdeco-dropdown{box-sizing:border-box;cursor:pointer;display:block;margin:0;padding:0;position:relative;}
.artdeco-dropdown__content{box-shadow:0 0 0 1px rgba(0,0,0,.15);background-color:var(--white,#fff);background-clip:padding-box;border-radius:2px;box-shadow:0 0 0 1px rgba(0,0,0,.15),0 6px 9px rgba(0,0,0,.2);transition:box-shadow 83ms;transition:visibility 0s linear 167ms,opacity 167ms cubic-bezier(.4,0,1,1) 0s,z-index 0s linear 167ms;padding:4px 0;opacity:0;visibility:hidden;z-index:-1;display:inline-block;position:absolute;min-width:168px;max-width:26.4rem;max-height:54.8rem;box-sizing:border-box;left:0;top:calc(100% + 8px);}
@media screen and (-ms-high-contrast:active){
  .artdeco-dropdown__content{border:1px solid activeborder;}
}
.artdeco-dropdown__content--placement-bottom{top:calc(100% + 8px);bottom:auto;}
.artdeco-dropdown__content--justification-right{left:auto;right:0;}
.artdeco-dropdown__content--has-arrow:after,.artdeco-dropdown__content--has-arrow:before{position:absolute;border:0 solid transparent;content:"";height:0;width:0;}
.artdeco-dropdown__content--arrow-right:after,.artdeco-dropdown__content--arrow-right:before{left:calc(100% - 24px);}
.artdeco-dropdown__content--placement-bottom.artdeco-dropdown__content--has-arrow:after{border-right-width:8px;border-bottom:8px solid var(--white,#fff);border-left-width:8px;top:-8px;margin-left:-9px;}
.artdeco-dropdown__content--placement-bottom.artdeco-dropdown__content--has-arrow:before{border-right-width:9px;border-bottom:9px solid rgba(0,0,0,.1);border-left-width:9px;top:-9px;margin-left:-10px;}
.artdeco-dropdown__content{background-color:var(--color-background-container)!important;border-radius:var(--corner-radius-medium)!important;}
.artdeco-dropdown__content:after,.artdeco-dropdown__content:before{border:none!important;content:none!important;}
.artdeco-dropdown__content--placement-bottom.artdeco-dropdown__content--justification-right{border-radius:var(--point-top-right)!important;}
.artdeco-hoverable-trigger{position:relative;}
.artdeco-hoverable-trigger--is-hoverable:before{content:"";display:none;position:absolute;}
.artdeco-hoverable-trigger--is-hoverable:hover:before{display:block;}
.artdeco-hoverable-trigger--content-placed-top:before{bottom:calc(100% - 1px);width:100%;height:14px;}
*,:after,:before{box-sizing:inherit;}
a[href],button{touch-action:manipulation;}
button{background:none;border:none;padding:0;cursor:pointer;}
.EntityPhoto-circle-3{width:48px;height:48px;box-sizing:border-box;background-clip:content-box;border:2px solid transparent;border-radius:49.9%;}
.hoverable-link-text.hoverable-link-text:hover{color:var(--color-action);text-decoration:underline;}
.align-items-center{align-items:center!important;}
.display-flex{display:flex!important;}
.full-height{height:100%!important;}
.flex-column{flex-direction:column!important;}
.flex-wrap{flex-wrap:wrap!important;}
.flex-grow-1{flex-grow:1!important;}
.justify-center{justify-content:center!important;}
.relative{position:relative!important;}
.absolute{position:absolute!important;}
.v-align-bottom{vertical-align:bottom!important;}
.visually-hidden{display:block!important;border:0!important;clip:rect(0 0 0 0)!important;height:1px!important;margin:-1px!important;overflow:hidden!important;padding:0!important;position:absolute!important;white-space:nowrap!important;width:1px!important;}
.text-align-right{text-align:right!important;}
.break-words{overflow-wrap:break-word!important;word-wrap:break-word!important;word-break:break-word!important;}
[class*=EntityPhoto-circle]{background-color:var(--color-background-container);color:var(--color-text);border:none;box-shadow:none;}
[class*=EntityPhoto-circle]{border-radius:var(--corner-radius-full)!important;}
.artdeco-card{overflow:visible;}
/*! CSS Used from: https://static-exp1.licdn.com/sc/h/brbpv1hsmmy16qgo3l9h5gujv */
.ivm-view-attr__img--centered{background-position:50%;background-size:cover;}
.ivm-view-attr__img-wrapper--use-img-tag .ivm-view-attr__img--centered{object-position:center;object-fit:cover;}
.content-analytics-entry-point .analytics-entry-point:before{content:"";display:block;border-top:none;}
.content-analytics-entry-point .analytics-entry-point .icon-and-text-container{display:flex;align-items:center;padding:8px 16px;}
.content-analytics-entry-point .analytics-entry-point .icon-and-text-container .LiIcon{height:24px;margin-right:8px;}
.comment-button.artdeco-button--tertiary.artdeco-button--4{padding-left:8px!important;padding-right:8px!important;}
.visually-hidden{position:absolute!important;height:1px;width:1px;overflow:hidden;clip:rect(1px 1px 1px 1px);clip:rect(1px,1px,1px,1px);white-space:nowrap;}
.entity-hovercard__a11y-trigger{flex-shrink:0;width:0;height:0;opacity:0;overflow:hidden;vertical-align:middle;}
.entity-hovercard__a11y-trigger:focus{width:10px;height:10px;opacity:1;margin-left:4px;}
.entity-hovercard__a11y-trigger:after{display:block;content:"";width:0;height:0;border:5px solid transparent;border-top:5px solid var(--color-icon-active);position:relative;top:3px;}
.presence-entity{display:flex;position:relative;}
.presence-entity--size-3{width:48px;height:48px;}
.presence-entity__image{background-size:cover;}
.presence-entity__indicator{position:absolute;box-shadow:0 0 0 2px var(--color-background-container);}
.presence-entity__indicator--size-3{bottom:2px;right:2px;}
.presence-entity__indicator--size-3{bottom:0;right:0;}
.presence-indicator{border-radius:50%;background:var(--color-background-container);flex-shrink:0;transition:background 167ms ease-in-out;box-sizing:border-box;animation:fade-in 167ms ease-in;}
.presence-indicator--size-3{width:12px;height:12px;}
.presence-indicator--is-online{background:var(--color-signal-positive);}
.reactions-react-button{overflow:visible;}
.reactions-react-button .artdeco-button__text{display:flex;}
.reactions-react-button{display:inline-flex;}
.react-button__trigger.artdeco-button--tertiary.artdeco-button--4{padding-left:8px!important;padding-right:8px!important;}
.reactions-menu__trigger{transform:rotate(180deg);min-width:0;height:0!important;opacity:0!important;max-width:0!important;padding:0!important;width:0!important;}
.reactions-icon{display:inline-block;background-repeat:no-repeat;background-size:100%;vertical-align:middle;}
.reactions-icon--stacked{background-color:var(--color-background-container);border-radius:50%;margin:0 0 0 -4px;}
.reactions-icon__consumption--small{height:16px!important;width:16px!important;}
.reshare-button.artdeco-button--tertiary.artdeco-button--4{padding-left:8px!important;padding-right:8px!important;}
.social-details-social-counts{--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);display:flex;align-items:flex-start;overflow:auto;margin:0 16px;padding:8px 0;border-bottom:1px solid var(--color-border-faint);}
.social-details-social-counts--with-social-proof{align-items:flex-start;justify-content:flex-end;overflow:hidden;}
.social-details-social-counts__item{list-style:none;margin-right:4px;color:var(--color-text-low-emphasis);}
.social-details-social-counts__item.social-details-social-counts__item{display:flex;align-items:center;}
.social-details-social-counts__item--with-social-proof{flex-shrink:0;}
.social-details-social-counts__item--with-social-proof:last-child{margin-right:0;}
.social-details-social-counts__item-text--with-social-proof{--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);}
.social-details-social-counts__count-value{--artdeco-reset-typography_getLineHeight:1.5;line-height:var(--artdeco-reset-typography_getLineHeight);}
.social-details-social-counts__count-value:disabled{cursor:default;}
.social-details-social-counts__reactions .hoverable-link-text:hover{text-decoration:none;}
.social-details-social-counts__reactions--with-social-proof{flex-grow:1;}
.social-details-social-counts__social-proof-container{--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);display:flex;flex-grow:1;align-items:center;overflow:hidden;flex-wrap:wrap;max-height:1.3333333em;margin-right:24px;}
.social-details-social-counts__social-proof-fallback-number{--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);overflow:hidden;width:100px;float:left;text-align:left;padding-left:4px;}
.social-details-social-counts__social-proof-fallback-number:hover{text-decoration:underline;}
.social-details-social-counts__social-proof-text{--artdeco-reset-typography_getLineHeight:1.33333;line-height:var(--artdeco-reset-typography_getLineHeight);float:left;margin-left:-100px;background-color:var(--color-background-container);padding-left:4px;}
.social-details-social-counts__social-proof-text:hover{text-decoration:underline;}
.send-privately-button.artdeco-button--tertiary.artdeco-button--4{padding-left:8px!important;padding-right:8px!important;}
/*! CSS Used from: https://static-exp1.licdn.com/sc/h/2ukjzd8u0223pj3og6y5mqnrk */
.feed-shared-inline-show-more-text{position:relative;max-width:928px;--artdeco-reset-typography_getLineHeight:1.42857;display:block;line-height:2rem!important;max-height:6rem;margin:0 16px;overflow:hidden;}
.feed-shared-inline-show-more-text *{line-height:inherit!important;}
.feed-shared-inline-show-more-text.feed-shared-inline-show-more-text--5-lines{display:block;line-height:2rem!important;max-height:10rem;overflow:hidden;}
.feed-shared-inline-show-more-text.feed-shared-inline-show-more-text--5-lines *{line-height:inherit!important;}
.feed-shared-inline-show-more-text .feed-shared-inline-show-more-text__see-more-less-toggle{background-color:var(--color-background-container);line-height:inherit;position:absolute;bottom:0;right:0;margin-bottom:0;padding-left:8px;}
.feed-shared-inline-show-more-text .see-more:hover{color:var(--color-action);text-decoration:underline;}
.feed-shared-actor__container-link:focus,.feed-shared-actor__container-link:hover{text-decoration:none;}
.feed-shared-actor__name{vertical-align:top;}
.feed-shared-social-action-bar{align-items:center;box-sizing:border-box;display:flex;justify-content:flex-start;margin:0;min-height:40px;padding:4px 8px;flex-wrap:wrap;}
.feed-shared-social-action-bar .social-actions-button{min-height:3.2rem;line-height:2.8rem;}
.feed-shared-social-action-bar .artdeco-button__text{font-size:1.4rem;}
.feed-shared-social-action-bar--full-width{display:grid;grid-auto-flow:column;justify-content:initial;column-gap:var(--spacing-half-x);padding:4px var(--spacing-one-and-a-half-x);}
.feed-shared-social-action-bar--full-width .social-actions-button{width:100%!important;}
.scaffold-layout--reflow .feed-shared-social-action-bar__action-button{flex:1 1 auto;}
.scaffold-layout--reflow .feed-shared-social-action-bar__action-button .social-actions-button{width:100%;}
@media screen and (max-width:427px){
  .scaffold-layout--reflow .feed-shared-social-action-bar__action-button .comment-button .artdeco-button__text,.scaffold-layout--reflow .feed-shared-social-action-bar__action-button .react-button__text,.scaffold-layout--reflow .feed-shared-social-action-bar__action-button .reshare-button .artdeco-button__text,.scaffold-layout--reflow .feed-shared-social-action-bar__action-button .send-privately-button .artdeco-button__text{display:none;}
  .scaffold-layout--reflow .feed-shared-social-action-bar__action-button .LiIcon{margin:0;}
}
@media screen and (min-width:768px){
  .scaffold-layout--reflow .feed-shared-social-action-bar__action-button{flex:0 1 auto;}
  .scaffold-layout--reflow .feed-shared-social-action-bar__action-button .social-actions-button{width:initial;}
}
.feed-shared-actor{flex-wrap:nowrap;padding:12px 16px 0;margin-bottom:8px;align-items:center;}
.feed-shared-actor--with-control-menu{padding-right:40px;}
.feed-shared-actor__meta{flex-grow:1;flex-basis:0;margin-left:8px;overflow:hidden;}
.feed-shared-actor__image{flex-shrink:0;}
.feed-shared-actor__container{left:0;right:0;top:0;bottom:0;}
.feed-shared-actor__container-link{overflow:hidden;}
.feed-shared-update-v2--minimal-padding .feed-shared-actor__container-link{margin-right:12px;}
.feed-shared-actor__avatar-image.feed-shared-actor__avatar-image{background-size:contain;background-repeat:no-repeat;}
.ivm-view-attr__img-wrapper--use-img-tag .feed-shared-actor__avatar-image.feed-shared-actor__avatar-image{object-fit:contain;}
.feed-shared-actor__description,.feed-shared-actor__sub-description{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;}
.feed-shared-actor__name{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:inline-block;}
.feed-shared-update-v2--minimal-padding .feed-shared-actor__title{display:flex;}
.feed-shared-update-v2__control-menu{top:4px;right:8px;}
.feed-shared-update-v2--minimal-padding .feed-shared-update-v2__control-menu{right:12px;}
.feed-shared-control-menu__content{max-width:355px;width:90vw;right:-8px;z-index:6;}
.feed-shared-update-v2{padding:0;margin:0 0 8px;}
.feed-shared-update-v2__commentary span a{position:relative;}
.feed-shared-update-v2__commentary{--artdeco-reset-typography_getFontSize:1.4rem;font-size:var(--artdeco-reset-typography_getFontSize);--artdeco-reset-typography_getLineHeight:1.42857;line-height:var(--artdeco-reset-typography_getLineHeight);color:var(--color-text-low-emphasis);}
.feed-shared-update-v2--minimal-padding .feed-shared-update-v2__commentary{color:var(--color-text);}
/*! CSS Used from: https://static-exp1.licdn.com/sc/h/3gjtukmop87trb4srsq2u4tes */
.artdeco-dropdown__content--arrow-right::after,.artdeco-dropdown__content--arrow-right::before{left:calc(100% - 21px);}
/*! CSS Used keyframes */
@keyframes fade-in{0%{opacity:0;}to{opacity:1;}}
