.LinkedInEditor {
  flex-grow: 1;
}
.LinkedInEditor-bar {
  background: hsl(45, 14%, 85%);
  padding: 0 4px 4px 0;
}
.LinkedInEditor-bar > * {
  margin-left: 4px;
  margin-top: 4px;
}
.LinkedInEditor-editor {
  background: white;
  padding: 20px;
}
.LinkedInEditor-editor p {
  margin: 0;
}

.LinkedInEditor .LinkedInEditor-Button {
  color: hsl(45, 14%, 35%);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  border-radius: 4px;
  border: 2px solid transparent;
}
.LinkedInEditor .LinkedInEditor-Button:hover {
  background: rgba(255, 255, 255, 0.8);
}
.LinkedInEditor .LinkedInEditor-Button.active {
  color: black;
  border: 2px solid black;
}

.LinkedInEditor .LinkedInEditor-Icon {
  fill: currentColor;
  line-height: 1;
  width: 20px;
}

.LinkedInEditor-emoji {
  position: absolute;
  z-index: 100;
  visibility: collapse;
}
.LinkedInEditor-emoji.visible {
  visibility: visible;
}
